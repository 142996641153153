import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useUserData from "hooks/useUserData";
import CookieConsentModal from "components/cookieConsent/CookieConsentModal";

interface ProtectedRouteProps {
    redirectTo: string;
}

const RequireLoginRoute: React.FC<ProtectedRouteProps> = (props) => {
    const { isUserLoggedIn } = useUserData();
    const location = useLocation();

    if (!isUserLoggedIn) {
        return (
            <Navigate
                to={props.redirectTo}
                state={{ redirectUrl: location.pathname }}
                replace
            />
        );
    }

    return <OutletWithCookieConsentModal />;
};

export const RequiredLocalUserRoute: React.FC<ProtectedRouteProps> = (
    props,
) => {
    const { isLocalLoginType } = useUserData();

    if (!isLocalLoginType) {
        return <Navigate to={props.redirectTo} replace />;
    }

    return <OutletWithCookieConsentModal />;
};

export const RequiredAdminRoute: React.FC<ProtectedRouteProps> = (props) => {
    const { isTenantAdmin, isAdmin } = useUserData();

    if (!(isTenantAdmin || isAdmin)) {
        return <Navigate to={props.redirectTo} replace />;
    }

    return <OutletWithCookieConsentModal />;
};

export const RequireTenantAdminRoute: React.FC<ProtectedRouteProps> = (
    props,
) => {
    const { isTenantAdmin } = useUserData();

    if (!isTenantAdmin) {
        return <Navigate to={props.redirectTo} replace />;
    }

    return <OutletWithCookieConsentModal />;
};

const OutletWithCookieConsentModal: React.FC = () => (
    <>
        <Outlet />
        <CookieConsentModal />
    </>
);

export default RequireLoginRoute;
