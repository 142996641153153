import * as React from "react";
import { useParams } from "react-router-dom";

import useQuery from "hooks/useQuery";
import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";

import IntegrationsBreadcrumbs from "components/integrations/IntegrationsBreadcrumbs";
import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import { SaveButton } from "components/shared/Button";
import Input from "components/shared/Input";

import { ReactComponent as HideIcon } from "@icons/hide.svg";

import { Breadcrumb } from "frends-ui-components/dist/types/breadcrumbs/Breadcrumbs";
import {
    Process,
    ManualTriggerParameter,
    ProcessDetails,
} from "model/process/Process";

import { INTEGRATIONS_BASE_PATH } from "AppRoutes";
import { PROCESSES_APIROUTE } from "./Integrations";

const ManualRun: React.FC = () => {
    const { processId } = useParams();

    const apiRoute = `${PROCESSES_APIROUTE}/${processId}`;

    const processFetch = useQuery<Process>(
        apiRoute,
        undefined,
        undefined,
        "Failed to fetch process.",
    );
    const process = processFetch.data;

    const manualTriggerParametersFetch = useQuery<ProcessDetails>(
        `${apiRoute}/details`,
        undefined,
        undefined,
        "Failed to fetch trigger parameters.",
    );

    const [manualTriggerParameters, setManualTriggerParameters] =
        React.useState<ManualTriggerParameter[]>([]);

    React.useEffect(() => {
        if (manualTriggerParametersFetch.data) {
            setManualTriggerParameters(
                manualTriggerParametersFetch.data.processTriggerParams.map(
                    (p) => ({
                        ...p,
                        value: p.defaultValue ?? "",
                    }),
                ),
            );
        }
    }, [manualTriggerParametersFetch.data]);

    const fetchingData =
        processFetch.isLoading || manualTriggerParametersFetch.isLoading;

    const handleParameterValueInputChange = (
        name: string,
        nextValue: string,
    ) => {
        const idx = manualTriggerParameters.findIndex((p) => p.name === name);
        const updated = { ...manualTriggerParameters[idx], value: nextValue };
        const next = [
            ...manualTriggerParameters.slice(0, idx),
            updated,
            ...manualTriggerParameters.slice(idx + 1),
        ];
        setManualTriggerParameters(next);
    };

    const run = useDeferredMutation(
        "PUT",
        undefined,
        "Failed to run process manually.",
    );

    const handleRunIntegrationClick = () => {
        run.reset();
        run.mutate({
            url: `${PROCESSES_APIROUTE}/${process?.productionDeploymentId}/execute`,
            body: manualTriggerParameters.reduce((acc, cur) => {
                acc[cur.name] = cur.value;
                return acc;
            }, {}),
        });
    };

    const breadcrumbs: Breadcrumb[] = [
        {
            label: "Run manually",
            href: `${INTEGRATIONS_BASE_PATH}/${processId}/manually-run`,
        },
    ];

    return (
        <S.GridContainer $margin={"1.5rem 0 0 0"}>
            <IntegrationsBreadcrumbs
                breadcrumbs={breadcrumbs}
                organizationId={process?.organizationId}
            />

            <S.ContentContainer>
                <ContainerWithLoadingSpinner loading={fetchingData}>
                    <S.RunManuallyForm>
                        <Text tag="h1">{process?.name ?? ""}</Text>

                        {manualTriggerParameters.map((v, i) => (
                            <fieldset key={i}>
                                <Input
                                    label={v.name}
                                    value={v.value}
                                    onChange={(nextValue) =>
                                        handleParameterValueInputChange(
                                            v.name,
                                            String(nextValue),
                                        )
                                    }
                                    disabled={run.isLoading}
                                />

                                {(v.description || v.secret) && (
                                    <p>
                                        {v.description && (
                                            <>
                                                <Text tag="span">
                                                    {v.description}
                                                </Text>
                                                &nbsp;
                                            </>
                                        )}

                                        {v.secret && (
                                            <span title="The parameter will not be logged.">
                                                <HideIcon />
                                            </span>
                                        )}
                                    </p>
                                )}
                            </fieldset>
                        ))}

                        <SaveButton
                            type="button"
                            onClick={handleRunIntegrationClick}
                            disabled={fetchingData}
                            loading={run.isLoading}
                            success={run.isSuccess}
                            error={run.isError}
                        >
                            Run integration
                        </SaveButton>
                    </S.RunManuallyForm>
                </ContainerWithLoadingSpinner>
            </S.ContentContainer>
        </S.GridContainer>
    );
};

export default ManualRun;
