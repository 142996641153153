import * as React from "react";
import {
    useLocation,
    useNavigate,
    Link as ReactRouterLink,
} from "react-router-dom";

import { AppContext } from "App";
import { Types } from "reducers/AppReducer";

import useUserData from "hooks/useUserData";
import useLogout from "hooks/useLogout";
import useQuery from "hooks/useQuery";
import useChangeSelectedOrganization from "hooks/useChangeSelectedOrganization";

import * as S from "@styled";
import Text from "../shared/Text";

import { ReactComponent as Cloud } from "@icons/nav_icon_cloud_outline.svg";
import { ReactComponent as Star } from "@icons/nav_icon_star.svg";
import { ReactComponent as Settings } from "@icons/nav_icon_settings.svg";
import frends_logo from "@icons/frends_logo.svg";

import "frends-ui-components/MenuBar";
import "frends-ui-components/MenuLink";
import "frends-ui-components/DropdownMenu";

import type DropdownMenuProps from "frends-ui-components/dist/types/dropdown-menu/DropdownMenu";
import { type ChangeEventDetail } from "frends-ui-components/dist/types/dropdown-menu/DropdownMenu";
import { OrganizationBase } from "model/Organization";

import {
    CHANGE_PASSWORD_PATH,
    INTEGRATION_CATALOG_BASE_PATH,
    INTEGRATIONS_BASE_PATH,
} from "AppRoutes";

const NavMenu: React.FC = () => (
    <S.MenuBarCssPropertiesWrapper>
        <frends-menu-bar>
            <BusinessLogo />
            <NavigationLinkList />
            <UserMenu />
        </frends-menu-bar>
    </S.MenuBarCssPropertiesWrapper>
);

const BusinessLogo: React.FC = () => {
    const { state } = React.useContext(AppContext);
    const portalLogo = state.lookAndFeel.portalLogo || frends_logo;

    return (
        <S.BusinessLogoWrapper slot="logo">
            <ReactRouterLink to="/">
                <img src={portalLogo} />
            </ReactRouterLink>
        </S.BusinessLogoWrapper>
    );
};

const NavigationLinkList: React.FC = () => {
    const { isTenantAdmin, isAdmin } = useUserData();
    const settingsShown = isTenantAdmin || isAdmin;

    return (
        <ul slot="links">
            <li>
                <NavigationLinkItem to={INTEGRATION_CATALOG_BASE_PATH}>
                    <Cloud /> Integration catalog
                </NavigationLinkItem>
            </li>
            <li>
                <NavigationLinkItem to={INTEGRATIONS_BASE_PATH}>
                    <Star /> My integrations
                </NavigationLinkItem>
            </li>

            {settingsShown && (
                <li>
                    <NavigationLinkItem to="/settings">
                        <Settings /> Settings
                    </NavigationLinkItem>
                </li>
            )}
        </ul>
    );
};

interface NavigationLinkProps extends React.PropsWithChildren {
    to: string;
}

const NavigationLinkItem: React.FC<NavigationLinkProps> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleLinkClick = (evt: React.MouseEvent<HTMLElement>) => {
        evt.preventDefault();
        navigate(props.to);
    };

    return (
        <frends-menu-link
            href={props.to}
            onClick={handleLinkClick}
            selected={location.pathname.startsWith(props.to) || undefined}
        >
            {props.children}
        </frends-menu-link>
    );
};

const UserMenu: React.FC = () => {
    const menuComponentRef = React.useRef<DropdownMenuProps>(null);
    const { dispatch } = React.useContext(AppContext);
    const navigate = useNavigate();
    const logout = useLogout();
    const {
        userName,
        selectedOrganization,
        isLocalLoginType,
        isTenantAdmin,
        availableOrganizations: initialOrganizations,
    } = useUserData();
    const changeSelectedOrganization = useChangeSelectedOrganization();

    const allOrganizationsForTenantAdminFetch = useQuery<OrganizationBase[]>(
        "organizations",
        undefined,
        { enabled: isTenantAdmin },
        "Failed to fetch organizations.",
    );
    const availableOrganizations =
        allOrganizationsForTenantAdminFetch.data ?? initialOrganizations;

    const getMenuOption = (label: string, value: number) => ({
        label,
        value,
    });

    const setMenuComponentOptions = () => {
        if (menuComponentRef.current) {
            menuComponentRef.current.options = menuOptions;
        }
    };

    const setMenuComponentSelected = () => {
        if (menuComponentRef.current) {
            menuComponentRef.current.selected = String(
                selectedOrganization?.id ?? "",
            );
        }
    };

    const handleChangePasswordClick = () => {
        navigate(CHANGE_PASSWORD_PATH);
        setMenuComponentSelected();
    };

    const handleShowCookiePolicyClick = () => {
        dispatch({
            type: Types.SHOW_COOKIE_POLICY,
            payload: {
                showCookiePolicy: true,
            },
        });
    };

    const menuOptions = React.useMemo(() => {
        const switchOrganizationOptions =
            availableOrganizations && availableOrganizations.length > 1
                ? {
                      name: "Switch organization",
                      options: availableOrganizations.map((org) =>
                          getMenuOption(org.name, org.id),
                      ),
                  }
                : undefined;

        const changePasswordOption = isLocalLoginType
            ? {
                  divider: !!switchOrganizationOptions,
                  options: [
                      getMenuOption(
                          "Change password",
                          UserActionOptionValue.ChangeLocalUserPassword,
                      ),
                  ],
              }
            : undefined;

        const showCookiePolicyOption = {
            divider: !!(switchOrganizationOptions || changePasswordOption),
            options: [
                getMenuOption(
                    "Cookie policy",
                    UserActionOptionValue.ShowCookiePolicy,
                ),
            ],
        };

        const logOutOption = {
            divider: true,
            options: [getMenuOption("Log out", UserActionOptionValue.Logout)],
        };

        return [
            ...(switchOrganizationOptions ? [switchOrganizationOptions] : []),
            ...(changePasswordOption ? [changePasswordOption] : []),
            showCookiePolicyOption,
            logOutOption,
        ];
    }, [availableOrganizations, isLocalLoginType]);

    React.useEffect(() => {
        setMenuComponentOptions();
        setMenuComponentSelected();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuOptions, selectedOrganization]);

    React.useEffect(() => {
        const onMenuOptionSelect = (event: CustomEvent) => {
            const details = event.detail as ChangeEventDetail;

            const selectedOpt = menuOptions
                .flatMap((optCategory) => optCategory.options)
                .find((opt) => opt.value === details.selected);

            if (!selectedOpt) {
                return;
            }

            if (selectedOpt.value === UserActionOptionValue.Logout) {
                logout();
                return;
            }

            if (
                selectedOpt.value ===
                UserActionOptionValue.ChangeLocalUserPassword
            ) {
                handleChangePasswordClick();
                return;
            }

            if (selectedOpt.value === UserActionOptionValue.ShowCookiePolicy) {
                handleShowCookiePolicyClick();
                return;
            }

            const nextOrganization = availableOrganizations?.find(
                (org) => org.id === selectedOpt.value,
            );

            if (
                nextOrganization &&
                nextOrganization.id !== selectedOrganization!.id
            ) {
                changeSelectedOrganization(nextOrganization.id);
            }
        };

        const { current } = menuComponentRef;

        current?.addEventListener("change", onMenuOptionSelect);

        return () => current?.removeEventListener("change", onMenuOptionSelect);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [availableOrganizations, selectedOrganization]);

    return (
        <>
            <frends-dropdown-menu ref={menuComponentRef} slot="menu">
                <S.UserMenuContentWrapper slot="toggle-content">
                    <Text size="small">{userName}</Text>
                    <Text size="small" color="grey">
                        {selectedOrganization?.name}
                    </Text>
                </S.UserMenuContentWrapper>
            </frends-dropdown-menu>
        </>
    );
};

enum UserActionOptionValue {
    ChangeLocalUserPassword = -1,
    Logout = -2,
    ShowCookiePolicy = -3,
}

export default NavMenu;
