import AppRoutes, {
    AdminRoutes,
    AppRoutesForLocalUser,
    INTEGRATION_CATALOG_BASE_PATH,
    LOGIN_PATH,
    SimpleRoutes,
} from "AppRoutes";
import RequireLoginRoute, {
    RequiredAdminRoute,
    RequiredLocalUserRoute,
} from "components/route/ProtectedRoute";
import Layout from "components/layout/Layout";
import useLookAndFeel from "hooks/useLookAndFeel";
import useInitialSetupReminder from "hooks/useInitialSetupReminder";
import * as React from "react";
import { Routes, Route, Navigate, PathRouteProps } from "react-router-dom";
import { AppReducerState, initialState, reducer } from "reducers/AppReducer";
import { ThemeProvider } from "styled-components";
import { defaultTheme } from "styles/theme";

export const AppContext = React.createContext<AppReducerState>({
    state: initialState,
    dispatch: () => null,
});

const App: React.FC = () => {
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const stylesLoaded = useLookAndFeel(dispatch);
    const { tryRemindTenantAdminAboutShopConfiguration } =
        useInitialSetupReminder();

    React.useEffect(() => {
        tryRemindTenantAdminAboutShopConfiguration();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRouteWithChildren = (route: PathRouteProps, index: number) => {
        const { element, children, ...rest } = route;
        return (
            <Route key={index} {...rest} element={element}>
                {children}
            </Route>
        );
    };

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            <ThemeProvider theme={defaultTheme}>
                {stylesLoaded ? (
                    <Routes>
                        {SimpleRoutes.map(getRouteWithChildren)}

                        <Route
                            element={
                                <RequireLoginRoute redirectTo={LOGIN_PATH} />
                            }
                        >
                            <Route element={<Layout />}>
                                {AppRoutes.map(getRouteWithChildren)}

                                <Route
                                    element={
                                        <RequiredLocalUserRoute redirectTo="" />
                                    }
                                >
                                    {AppRoutesForLocalUser.map(
                                        getRouteWithChildren,
                                    )}
                                </Route>

                                <Route
                                    element={
                                        <RequiredAdminRoute redirectTo="" />
                                    }
                                >
                                    {AdminRoutes.map(getRouteWithChildren)}
                                </Route>
                            </Route>
                        </Route>

                        <Route
                            path="*"
                            element={
                                <Navigate
                                    to={INTEGRATION_CATALOG_BASE_PATH}
                                    replace
                                />
                            }
                        />
                    </Routes>
                ) : null}
            </ThemeProvider>
        </AppContext.Provider>
    );
};

export default App;
