import * as React from "react";

import AppInsights from "../../shared/AppInsights";
import frends_logo from "@icons/frends_logo.svg";

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        AppInsights.TrackException(error.message);
        console.error("Error caught by boundary:", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorFallback />;
        }

        return this.props.children;
    }
}

const ErrorFallback: React.FC = () => (
    <div
        style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
        }}
    >
        <img src={frends_logo} />

        <h1
            style={{
                marginTop: "2rem",
            }}
        >
            We&apos;re having trouble displaying this page
        </h1>

        <p
            style={{
                marginTop: "1rem",
            }}
        >
            Please contact&nbsp;
            <a href="mailto:support@frends.com">support@frends.com</a>
        </p>
    </div>
);

export default ErrorBoundary;
