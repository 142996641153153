import * as React from "react";
import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import { ProcessInstanceDetails } from "model/process/ProcessInstanceDetails";
import { useParams } from "react-router-dom";
import IntegrationsBreadcrumbs from "components/integrations/IntegrationsBreadcrumbs";
import { INTEGRATIONS_BASE_PATH } from "AppRoutes";
import { Breadcrumb } from "frends-ui-components/dist/types/breadcrumbs/Breadcrumbs";
import { Process } from "model/process/Process";
import Text from "components/shared/Text";
import DescriptionList, {
    DescriptionListItem,
} from "components/shared/DescriptionList";
import Time from "shared/Time";
import Button from "components/shared/Button";
import TitleWithArrowLink from "components/shared/TitleWithArrowLink";
import useQuery from "hooks/useQuery";
import { DataResponse } from "model/DataResponse";

const IntegrationInstanceDetails: React.FC = () => {
    const { processId, instanceExecutionIdentifier } = useParams();

    const processFetch = useQuery<Process>(
        `processes/${processId}`,
        undefined,
        undefined,
        "Failed to fetch process.",
    );
    const process = processFetch.data;

    const instanceDetailsFetch = useQuery<DataResponse<ProcessInstanceDetails>>(
        `processinstance/${process?.prodAgentGroupId}/${instanceExecutionIdentifier}/details`,
        undefined,
        { enabled: !!process },
        "Failed to fetch process instance details.",
    );
    const instanceDetails = instanceDetailsFetch.data?.data;

    const instanceListPath = `${INTEGRATIONS_BASE_PATH}/${processId}/instances`;

    const breadcrumbs: Breadcrumb[] = [
        {
            label: "Instance list",
            href: instanceListPath,
        },
        {
            label: "Instance details",
            href: `${INTEGRATIONS_BASE_PATH}/${processId}/instances/${instanceExecutionIdentifier}`,
        },
    ];

    const isFetching = instanceDetailsFetch.isLoading || processFetch.isLoading;
    const hasProcessParameters =
        Object.keys(instanceDetails?.processParameters || {})?.length > 0;

    return (
        <S.GridContainer $margin={"1.5rem 0 0 0"}>
            <IntegrationsBreadcrumbs
                breadcrumbs={breadcrumbs}
                organizationId={process?.organizationId}
            />

            <ContainerWithLoadingSpinner loading={isFetching}>
                <S.ContentContainer style={{ gap: "1.313rem" }}>
                    {process && (
                        <TitleWithArrowLink
                            title={process.name}
                            href={instanceListPath}
                        />
                    )}
                    {!instanceDetailsFetch.isLoading && !instanceDetails && (
                        <Text tag="h3">
                            Instance was not found or instance data could not be
                            loaded
                        </Text>
                    )}

                    {instanceDetails && (
                        <>
                            <Text tag="h3">Integration instance</Text>
                            <DescriptionList $itemsPerRow={4}>
                                <DescriptionListItem
                                    dt="State"
                                    dd={instanceDetails.state}
                                />
                                <DescriptionListItem
                                    dt="Start time"
                                    dd={Time.toDetailedLocalTime(
                                        instanceDetails.startTimeUtc,
                                    )}
                                />
                                {instanceDetails.endTimeUtc && (
                                    <>
                                        <DescriptionListItem
                                            dt="Duration"
                                            dd={instanceDetails.duration}
                                        />
                                        <DescriptionListItem
                                            dt="End time"
                                            dd={Time.toDetailedLocalTime(
                                                instanceDetails.endTimeUtc,
                                            )}
                                        />
                                    </>
                                )}
                            </DescriptionList>
                            {hasProcessParameters && (
                                <>
                                    <Text tag="h3">
                                        Integration instance parameters
                                    </Text>
                                    <DescriptionList>
                                        {Object.entries(
                                            instanceDetails.processParameters!,
                                        ).map(([key, value]) => (
                                            <DescriptionListItem
                                                key={key}
                                                dt={key}
                                                dd={value}
                                            />
                                        ))}
                                    </DescriptionList>
                                </>
                            )}
                            {instanceDetails.processReturnValues &&
                                instanceDetails.processReturnValues.length >
                                    0 && (
                                    <>
                                        <Text tag="h3">
                                            Integration instance result
                                        </Text>
                                        <Text tag="p">
                                            {
                                                instanceDetails.processReturnValues
                                            }
                                        </Text>
                                    </>
                                )}
                            <Button
                                variant="bordered"
                                type="link"
                                href={instanceListPath}
                            >
                                Back to executions
                            </Button>
                        </>
                    )}
                </S.ContentContainer>
            </ContainerWithLoadingSpinner>
        </S.GridContainer>
    );
};

export default IntegrationInstanceDetails;
