import * as React from "react";
import * as S from "@styled";
import Text from "components/shared/Text";
import { useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "components/shared/Breadcrumbs";
import {
    INTEGRATION_CATALOG_BASE_PATH,
    TEMPLATES_API_BASE_PATH,
} from "AppRoutes";
import { Breadcrumb } from "frends-ui-components/dist/types/breadcrumbs/Breadcrumbs";
import useQuery from "hooks/useQuery";
import { TemplateDetail } from "model/Template";
import Tag from "components/shared/Tag";
import Button from "components/shared/Button";
import TitleWithArrowLink from "components/shared/TitleWithArrowLink";
import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import BpmnViewer from "components/integrations/bpmn/BpmnViewer";

const TemplateDetails: React.FC = () => {
    const { templateId } = useParams();
    const navigate = useNavigate();

    const templateResponse = useQuery<TemplateDetail>(
        `${TEMPLATES_API_BASE_PATH}/${templateId}`,
        undefined,
        undefined,
        "Failed to fetch template details.",
    );
    const template = templateResponse.data;

    const breadcrumbs: Breadcrumb[] = [
        {
            label: "Integration catalog",
            href: INTEGRATION_CATALOG_BASE_PATH,
        },
        {
            label: "Template details",
            href: `${INTEGRATION_CATALOG_BASE_PATH}/${templateId}`,
        },
    ];

    return (
        <S.TemplateDetailsPageContainer>
            <ContainerWithLoadingSpinner
                loading={
                    templateResponse.isFetching || templateResponse.isLoading
                }
            >
                <Breadcrumbs breadcrumbs={breadcrumbs} onClick={navigate} />

                <S.ContentContainer>
                    <S.TemplateDetailsContentContainer>
                        {templateResponse.isLoading && (
                            <TitleWithArrowLink
                                href={INTEGRATION_CATALOG_BASE_PATH}
                                title="Loading template details..."
                            />
                        )}

                        {!templateResponse.isLoading && template && (
                            <>
                                <TitleWithArrowLink
                                    href={INTEGRATION_CATALOG_BASE_PATH}
                                    title={template.name}
                                />
                                <BpmnViewer
                                    bpmnXml={template.bpmn}
                                    elementParameters={
                                        template.elementParameters
                                    }
                                />
                                {template.description && (
                                    <S.TemplateDetailsDescriptionContainer>
                                        <Text tag="p">
                                            {template.description}
                                        </Text>
                                    </S.TemplateDetailsDescriptionContainer>
                                )}
                                <S.TemplateDetailsTagsContainer>
                                    <S.CardTagsContainer>
                                        {template.processTags?.map(
                                            (tag, index) => (
                                                <Tag key={index}>{tag}</Tag>
                                            ),
                                        )}
                                    </S.CardTagsContainer>
                                </S.TemplateDetailsTagsContainer>
                                <S.TemplateDetailsActionsContainer>
                                    <Button
                                        type="link"
                                        href={`${INTEGRATION_CATALOG_BASE_PATH}/${templateId}/setup`}
                                    >
                                        Select Integration & Start On-boarding
                                        Wizard
                                    </Button>
                                    <Button
                                        variant="bordered"
                                        type="link"
                                        href={`${INTEGRATION_CATALOG_BASE_PATH}/${templateId}/changelog`}
                                    >
                                        Review change log
                                    </Button>
                                </S.TemplateDetailsActionsContainer>
                            </>
                        )}

                        {!templateResponse.isLoading && !template && (
                            <TitleWithArrowLink
                                href={INTEGRATION_CATALOG_BASE_PATH}
                                title="Template could not be loaded"
                            />
                        )}
                    </S.TemplateDetailsContentContainer>
                </S.ContentContainer>
            </ContainerWithLoadingSpinner>
        </S.TemplateDetailsPageContainer>
    );
};

export default TemplateDetails;
