import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";
import Cookie from "./Cookie";

class AppInsights {
    private appInsights: ApplicationInsights | null = null;
    constructor() {
        this.InitializeAppInsights();
    }

    public TrackException(message: string) {        
        if (this.appInsights?.core.isInitialized) {
            this.appInsights.trackException({ exception: new Error(message), severityLevel: SeverityLevel.Warning, properties: { Toast: "Error" } });
        }
    }

    private InitializeAppInsights() {

        const appInsightsConnectionString = Cookie.getCookieValue("ai_connection_string");
        if (!appInsightsConnectionString) {
            return;
        }

        const enabledUserTracking = Cookie.getCookieValue("cookieconsent_status") === "allow";

        const ai = new ApplicationInsights({
            config: {
                connectionString: appInsightsConnectionString,
                disableCookiesUsage: !enabledUserTracking,
                isStorageUseDisabled: !enabledUserTracking,
                disablePageUnloadEvents: ["unload"],
                disableUserInitMessage: true
            }
        })
        ai.loadAppInsights();
        ai.trackPageView();
        this.appInsights = ai;
    }
}

export default new AppInsights();
