import styled from "styled-components";

import { screenReaderOnlyStyles } from "./ScreenReadersOnly";

export const MyIntegrationsTitleContainer = styled.div`
    margin-top: 4rem;
    display: flex;
    justify-content: center;
`;

export const MyIntegrationsKPIContainer = styled.div`
    margin: 1.125rem 0 2rem 0;
    display: grid;
    gap: 0.563rem;

    > frends-text:nth-child(1) {
        margin-left: 0.5rem;
    }

    > div:nth-child(2) {
        display: flex;
        justify-content: center;
        gap: 1.5rem;
        padding: 1rem 1rem 1.5rem 1rem;
        border: 1px solid ${(props) => props.theme.grey2};
        border-radius: 0.938rem;
    }
`;

export const MyIntegrationsCount = styled.p`
    text-align: center;
    padding: 0 1rem;
`;

export const MyIntegrationsCountLarge = styled(MyIntegrationsCount)`
    padding: 0 2rem;

    > frends-text:first-child {
        --frends-text--font-size: 2.5rem;
    }
`;

export const MyIntegrationsList = styled.ul<{ $cols: 1 | 3 }>`
    list-style: none;
    margin-top: 1.188rem;
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(${(props) => props.$cols}, 1fr);
    gap: 1.5rem;
`;

export const MyIntegrationsListItem = styled.li`
    display: grid;
    height: 100%;
    gap: 1rem;
    padding: 1rem;
    background-color: ${(props) => props.theme.white};
    border: 1px solid ${(props) => props.theme.grey2};
    border-radius: 0.938rem;

    &:not(:hover) {
        frends-link[text="Delete"] {
            display: none;
        }
    }
`;

export const MyIntegrationsListItemKPIContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
`;

export const MyIntegrationsListItemActiveToggleContainer = styled.div`
    min-height: 1.313 rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    --frends-toggle--container--display: flex;
    --frends-toggle--container--flex-direction: row-reverse;
    --frends-toggle--container--align-items: center;
    --frends-toggle--label--font-size: 1rem;

    --frends-toggle--width: 2.5rem;
    --frends-toggle--height: 1.25rem;
    --frends-toggle--toggle-button--height: 0.9rem;
    --frends-toggle--toggle-button--width: 0.9rem;
    --frends-toggle--toggle-button--margin-left: 0.125rem;
    --frends-toggle--toggle-button--margin-bottom: 0.0653rem;
    --frends-toggle--toggle-button--translateX: 1.2rem;
`;

export const MyIntegrationsListItemActionsContainer = styled.div<{
    $justifyContent: "space-between" | "space-evenly";
}>`
    display: flex;
    justify-content: ${(props) => props.$justifyContent};
    flex-wrap: wrap;
    gap: 0.813rem;
`;

export const MyIntegrationsLargeListItem = styled(MyIntegrationsListItem)`
    gap: 1.313rem;
`;

export const MyIntegrationsLargeListItemTitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.938rem;
`;

export const MyIntegrationsLargeListItemActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
`;

export const RunManuallyForm = styled.form`
    display: grid;
    gap: 1.313rem;

    fieldset {
        display: grid;
        gap: 0.5rem;

        > p {
            display: flex;
            align-items: center;
        }
    }

    --frends-button--margin: 0 0 0 auto;
`;

export const IntegrationInstancesTable = styled.table`
    thead tr {
        th {
            vertical-align: middle;

            > div {
                display: flex;
                height: 2.5rem;
            }
        }

        th:last-child > div {
            justify-content: flex-end;
        }
    }

    tbody tr {
        td.select {
            width: 5%;
        }

        td.state {
            width: 14%;

            > div {
                display: flex;
                align-items: center;
                gap: 0.625rem;

                > svg {
                    fill: var(--status-fill-color);
                }

                &.started {
                    --status-fill-color: ${(props) =>
                        props.theme.orangePrimary};
                }

                &.exception {
                    --status-fill-color: ${(props) => props.theme.warningDark};
                }

                &.exception.acknowledged {
                    --status-fill-color: ${(props) => props.theme.grey2};
                }

                &.finished {
                    --status-fill-color: ${(props) => props.theme.uiGreen};
                }
            }
        }

        td.starttime,
        td.endtime {
            width: 17%;
        }

        td.duration {
            width: 12%;
        }

        td.comment {
            width: 20%;
        }

        td.view-details {
            width: auto;

            > div {
                display: flex;
                justify-content: flex-end;
            }
        }
    }

    .sr-only {
        ${screenReaderOnlyStyles};
        width: 0;
    }
`;

export const IntegrationInstancesConfirmActionForm = styled.form`
    margin-top: 1.5rem;

    > div {
        margin-top: 1.5rem;
        width: 54rem;
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
    }
`;
