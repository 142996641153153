import * as React from "react";
import * as S from "@styled";

import useQuery from "hooks/useQuery";
import Input from "components/shared/Input";
import Dropdown from "components/shared/Dropdown";
import Text from "components/shared/Text";
import { SaveButton } from "components/shared/Button";
import { Organization } from "model/Organization";
import { Environment, EnvironmentName } from "model/ShopConfiguration";

const OrganizationSettingsForm: React.FC<OrganizationSettingsFormProps> = (
    props,
) => {
    const [organization, setOrganization] = React.useState<Organization>({
        name: "",
        contactEmail: "",
        id: 0,
        domains: [],
    });
    const [formDirty, setFormDirty] = React.useState(false);
    const [saveTried, setSaveTried] = React.useState(false);

    React.useEffect(() => {
        if (props.organization) {
            setOrganization(props.organization);
        }
    }, [props.organization]);

    const processTagsFetch = useQuery<string[]>(
        "processes/tags",
        undefined,
        undefined,
        "Failed to fetch process tags.",
    );
    const processTags = processTagsFetch.data;

    const environmentsFetch = useQuery<Environment[]>(
        "shopconfiguration/integrationEnvironments",
        undefined,
        undefined,
        "Failed to fetch environments.",
    );
    const environments = environmentsFetch.data;

    const updateOrganizationProp = (
        key: keyof Organization,
        value: string | string[] | undefined,
    ) => {
        setOrganization((prev) => ({ ...prev, [key]: value }));
        setFormDirty(true);
    };

    const handleNameChange = (value: string) =>
        updateOrganizationProp("name", value);
    const nameValid = organization.name !== "";

    const handleContactEmailChange = (value: string) =>
        updateOrganizationProp("contactEmail", value);

    const contactEmailValid = organization.contactEmail !== "";
    const testAgentGroupValid = !!organization.testAgentGroup;
    const prodAgentGroupValid = !!organization.prodAgentGroup;

    const splitAndTrimValues = (value: string) =>
        value
            .trim()
            .split(",")
            .map((t) => t.trim())
            .filter((t) => t !== "," && t !== "");

    const handledomainsChange = (newDomains: string) => {
        const domains = splitAndTrimValues(newDomains);
        updateOrganizationProp("domains", domains);
    };

    const handleDefaultProcessTagsChange = (newTags: string) => {
        const tags = splitAndTrimValues(newTags);
        updateOrganizationProp("defaultProcessTags", tags);
    };

    const processTagOptions =
        processTags?.map((t) => ({ label: t, value: t })) ?? [];

    const handleViewableProcessTagsChange = (values: string[]) =>
        updateOrganizationProp("viewableProcessTags", values);

    const getEnvironmentAgentGroupsOptions = (env: Environment) =>
        env.agentGroups.map((ag) => ({
            label: ag.displayName,
            value: ag.internalName,
        }));

    const getSelectedEnvironmentAgentGroup = React.useCallback(
        (envName: EnvironmentName) => {
            if (envName === EnvironmentName.Test) {
                return organization.testAgentGroup;
            } else {
                return organization.prodAgentGroup;
            }
        },
        [organization],
    );

    const handleEnvironmentAgentGroupChange = (
        envName: EnvironmentName,
        agentGroup: string,
    ) => {
        const agenGroupPropKey =
            envName === EnvironmentName.Test
                ? "testAgentGroup"
                : "prodAgentGroup";
        updateOrganizationProp(agenGroupPropKey, agentGroup);
    };

    const formValid =
        nameValid &&
        contactEmailValid &&
        testAgentGroupValid &&
        prodAgentGroupValid;

    const handleSaveOrganizationClick = () => {
        setSaveTried(true);

        if (formValid) {
            props.onSave(organization);
        }
    };

    return (
        <S.OrganizationSettingsForm>
            <Input
                label="Name"
                type="text"
                value={organization.name ?? ""}
                onChange={handleNameChange}
                invalid={saveTried && !nameValid}
                errortext="Name is required"
            />

            <Input
                label="Contact email"
                type="text"
                value={organization.contactEmail ?? ""}
                onChange={handleContactEmailChange}
                invalid={saveTried && !contactEmailValid}
                errortext="Contact email is required"
            />

            <Input
                label="Domains to auto assign to this organization"
                tooltip="Multiple domains can be added, separate with comma"
                type="text"
                value={
                    organization.domains.length > 0
                        ? organization.domains.join(",")
                        : ""
                }
                onChange={handledomainsChange}
            />

            <Input
                label="Assign Tag to Processes created by organization"
                tooltip="Multiple tags can be added, separate with comma"
                type="text"
                value={organization.defaultProcessTags?.join(",") ?? ""}
                onChange={handleDefaultProcessTagsChange}
            />

            <Dropdown
                label="Processes with Tag viewable by organization"
                placeholder="Select tags"
                multivalue
                searchable
                options={processTagOptions}
                selected={organization.viewableProcessTags}
                onChange={handleViewableProcessTagsChange}
            />

            <fieldset>
                <Text tag="h3">Agent groups to run integrations in</Text>

                {environments &&
                    environments.map((env, i) => (
                        <Dropdown
                            key={i}
                            label={env.displayName}
                            placeholder="Select an Agent group"
                            options={getEnvironmentAgentGroupsOptions(env)}
                            selected={getSelectedEnvironmentAgentGroup(
                                env.displayName,
                            )}
                            onChange={(value) =>
                                handleEnvironmentAgentGroupChange(
                                    env.displayName,
                                    String(value),
                                )
                            }
                            invalid={
                                saveTried &&
                                (!testAgentGroupValid || !prodAgentGroupValid)
                            }
                            errortext="Organization must have target Agent groups for both test and prod environments"
                        />
                    ))}
            </fieldset>

            <S.SettingsSaveButtonContainer>
                <SaveButton
                    type="button"
                    disabled={!formDirty || (saveTried && !formValid)}
                    loading={props.saving}
                    success={props.saveSuccess}
                    error={props.saveError}
                    onClick={handleSaveOrganizationClick}
                >
                    {props.saveButtonText ?? "Save"}
                </SaveButton>
            </S.SettingsSaveButtonContainer>
        </S.OrganizationSettingsForm>
    );
};

interface OrganizationSettingsFormProps {
    onSave: (organization: Organization) => void;
    saving: boolean;
    saveSuccess: boolean;
    saveError: boolean;
    organization?: Organization;
    saveButtonText?: string;
}

export default OrganizationSettingsForm;
