import * as React from "react";
import * as S from "@styled";
import { useNavigate, useParams } from "react-router-dom";

import useQuery from "hooks/useQuery";
import useDeferredMutation from "hooks/useDeferredMutation";
import useDeleteMutation from "hooks/useDeleteMutation";
import { queryClient } from "index";

import TitleWithArrowLink from "components/shared/TitleWithArrowLink";
import OrganizationUsers from "components/settings/organization/organizationSettings/OrganizationUsers";
import OrganizationSettingsForm from "components/settings/organization/organizationSettings/OrganizationSettingsForm";
import OrganizationAvailableIntegrations from "components/settings/organization/organizationSettings/OrganizationAvailableIntegrations";
import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Button from "components/shared/Button";
import ConfirmDeleteModal from "components/settings/ConfirmDeleteModal";
import MigrateOrganizationProcessesModal from "components/settings/organization/organizationSettings/MigrateOrganizationProcessesModal";

import { Organization } from "model/Organization";
import { User } from "model/User";

const OrganizationSettings: React.FC = () => {
    const navigate = useNavigate();
    const { organizationId } = useParams();
    const organizationIdAsNumber = Number(organizationId);
    const [organizationTitle, setOrganizationTitle] = React.useState({
        title: "loading organization...",
        href: "/settings/organizations",
    });
    const [organization, setOrganization] = React.useState<Organization>();

    const [previousProdAgentGroup, setPreviousProdAgentGroup] =
        React.useState<string>();

    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] =
        React.useState(false);

    const apiRouteBase = `organizations/${organizationIdAsNumber}`;

    const organizationFetch = useQuery<Organization>(
        apiRouteBase,
        undefined,
        undefined,
        "Failed to fetch organization.",
    );

    React.useEffect(() => {
        if (!organizationFetch.isFetched) {
            return;
        }

        if (!organizationFetch.data) {
            navigate("/settings/organizations");
            return;
        }

        setOrganizationTitle((prev) => ({
            ...prev,
            title: organizationFetch.data.name,
        }));
        setOrganization(organizationFetch.data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationFetch.isFetched, organizationFetch.data]);

    const putOrganization = useDeferredMutation(
        "PUT",
        () => queryClient.invalidateQueries({ queryKey: [apiRouteBase] }),
        "Failed to save organization",
    );

    const handleSaveOrganizationClick = (nextOrganization: Organization) => {
        setPreviousProdAgentGroup(organization!.prodAgentGroup);
        saveOrganization(nextOrganization);
    };

    const handleRevertProdAgentGroupChangeClick = () => {
        setPreviousProdAgentGroup(undefined);
        saveOrganization({
            ...organization!,
            prodAgentGroup: previousProdAgentGroup,
        });
    };

    const saveOrganization = (nextOrganization: Organization) =>
        putOrganization.mutate({ url: apiRouteBase, body: nextOrganization });

    const usersApiRoute = `users/organization/${organizationIdAsNumber}`;

    const organizationUsersFetch = useQuery<User[]>(
        usersApiRoute,
        undefined,
        undefined,
        "Failed to fetch organization users.",
    );

    const handleDeleteOrganizationSuccess = () => {
        queryClient.invalidateQueries({ queryKey: ["organizations"] });
        navigate("/settings/organizations");
    };

    const delOrganization = useDeleteMutation(
        apiRouteBase,
        handleDeleteOrganizationSuccess,
        "Failed to delete organization.",
    );

    const handleDeleteOrganization = () => delOrganization.mutate();

    const openMigrateOrganizationProcessesModal =
        previousProdAgentGroup &&
        previousProdAgentGroup !== organization!.prodAgentGroup;

    return (
        <>
            <ContainerWithLoadingSpinner loading={organizationFetch.isLoading}>
                <S.GridContainer $gap="1.313rem">
                    <TitleWithArrowLink
                        title={organizationTitle.title}
                        href={organizationTitle.href}
                    />

                    {!organizationFetch.isLoading && organizationIdAsNumber && (
                        <OrganizationUsers
                            titleTag="h3"
                            organizationId={organizationIdAsNumber}
                            users={organizationUsersFetch.data}
                            loadingUsers={organizationUsersFetch.isLoading}
                            canToggleUserStatus
                        />
                    )}

                    {!organizationFetch.isLoading && (
                        <OrganizationSettingsForm
                            organization={organization}
                            onSave={handleSaveOrganizationClick}
                            saving={putOrganization.isLoading}
                            saveSuccess={putOrganization.isSuccess}
                            saveError={putOrganization.isError}
                        />
                    )}

                    {!organizationFetch.isLoading && organizationIdAsNumber && (
                        <OrganizationAvailableIntegrations
                            organizationId={organizationIdAsNumber}
                            tags={organization?.viewableTemplateTags}
                            loadingTags={organizationFetch.isLoading}
                        />
                    )}

                    <Button
                        variant="bordered"
                        onClick={() => setOpenConfirmDeleteModal(true)}
                    >
                        Delete organization
                    </Button>
                </S.GridContainer>
            </ContainerWithLoadingSpinner>

            {openMigrateOrganizationProcessesModal && (
                <MigrateOrganizationProcessesModal
                    organization={organization!}
                    previousProdAgentGroup={previousProdAgentGroup}
                    onRevertProdAgentGroupChangeClick={
                        handleRevertProdAgentGroupChangeClick
                    }
                    onClose={() => setPreviousProdAgentGroup(undefined)}
                />
            )}

            {openConfirmDeleteModal && (
                <ConfirmDeleteModal
                    title="Do you really want to delete this organization?"
                    body={`All the data related to this organization will be lost. This action can not be reversed. Do you still want to delete ${organization!.name}?`}
                    onClose={() => setOpenConfirmDeleteModal(false)}
                    onDelete={handleDeleteOrganization}
                    deleting={delOrganization.isLoading}
                    deleteError={delOrganization.isError}
                />
            )}
        </>
    );
};

export default OrganizationSettings;
