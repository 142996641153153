import styled from "styled-components";

export const CookieConsentModalContent = styled.div`
    margin-top: 1.5rem;
    max-width: 54rem;
    display: grid;
    gap: 1rem;

    a {
        color: var(--accent-color);
    }

    p:has(+ ol) {
        margin-top: 1.5rem;
    }

    ol {
        list-style: decimal;
        padding-left: 2.5rem;
    }

    p:has(+ div:last-child) {
        margin-top: 1.5rem;
        font-family: "Gilroy-Bold", sans-serif;
    }

    div:last-child {
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
    }
`;
