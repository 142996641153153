import * as React from "react";

import AppInsights from "../shared/AppInsights";

import type FrendsToaster from "frends-ui-components/dist/types/toaster/Toaster";

const useToaster = () => {
    const toasterRef = React.useRef<FrendsToaster | null>(null);

    React.useEffect(() => {
        toasterRef.current = document.querySelector(
            "frends-toaster",
        ) as FrendsToaster;
        if (!toasterRef.current) {
            console.warn("frends-toaster element not found in the DOM");
        }
    }, []);

    const toastSuccess = (message: string) => {
        if (toasterRef.current) {
            toasterRef.current.toastSuccess(message);
        }
    };

    const toastError = (message: string) => {
        AppInsights.TrackException(message);
        if (toasterRef.current) {
            toasterRef.current.toastError(message);
        }
    };

    return { toastSuccess, toastError };
};

export default useToaster;
