import * as React from "react";

import { AppContext } from "App";
import { AppReducerState, Types } from "reducers/AppReducer";

import { UserLoginType, UserRole } from "model/User";
import { AppUserData } from "model/UserData";
import Cookie from "shared/Cookie";

const useUserData = () => {
    const {
        state: { userData },
        dispatch,
    } = React.useContext<AppReducerState>(AppContext);

    const isUserLoggedIn = !!userData;
    const isTenantAdmin =
        userData?.userRoleInSelectedOrganization === UserRole.TenantAdmin;
    const isAdmin = userData?.userRoleInSelectedOrganization === UserRole.Admin;
    const userName = userData?.userName;
    const selectedOrganization = userData?.selectedOrganization;
    const availableOrganizations = userData?.availableOrganizations;
    const isLocalLoginType = userData?.userLoginType === UserLoginType.Local;

    const setUserData = (userData?: AppUserData) => {
        dispatch({
            type: Types.SET_USER_DATA,
            payload: { userData },
        });
    };

    const setUserDataFromCookie = () => setUserData(Cookie.getUserData());

    const clearUserData = () => {
        Cookie.removeUserDataCookie();
        setUserData();
    };

    return {
        isUserLoggedIn,
        isTenantAdmin,
        isAdmin,
        userName,
        selectedOrganization,
        availableOrganizations,
        isLocalLoginType,
        setUserDataFromCookie,
        clearUserData,
    };
};

export default useUserData;
