import * as React from "react";
import * as S from "@styled";

import Stepper from "components/shared/Stepper";
import { Step } from "frends-ui-components/dist/types/stepper/Stepper";
import useQuery from "hooks/useQuery";
import { useLocation, useParams } from "react-router-dom";
import {
    INTEGRATION_CATALOG_BASE_PATH,
    INTEGRATIONS_BASE_PATH,
    TEMPLATES_API_BASE_PATH,
} from "AppRoutes";
import Breadcrumbs from "components/shared/Breadcrumbs";
import { Breadcrumb } from "frends-ui-components/dist/types/breadcrumbs/Breadcrumbs";
import Text from "components/shared/Text";
import { TemplateDetail } from "model/Template";
import ConfigureIntegrationStep from "../../components/integrations/wizardSteps/ConfigureIntegrationStep";
import TestIntegrationStep from "../../components/integrations/wizardSteps/TestIntegrationStep";
import DeployIntegrationStep from "../../components/integrations/wizardSteps/DeployIntegrationStep";
import usePostMutation from "hooks/usePostMutation";
import {
    Process,
    ProcessVariable,
    ProcessDetails,
} from "model/process/Process";
import { Parameter } from "model/Parameter";
import { DeployedProcess } from "model/process/DeployedProcess";
import Modal from "components/shared/Modal";
import LoadingSpinner from "components/shared/LoadingSpinner";
import Button from "components/shared/Button";
import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import TitleWithArrowLink from "components/shared/TitleWithArrowLink";
import { PROCESSES_APIROUTE } from "pages/integrations/Integrations";

const TemplateWizard = () => {
    const location = useLocation();
    const { templateId } = useParams();

    const templateResponse = useQuery<TemplateDetail>(
        `${TEMPLATES_API_BASE_PATH}/${templateId}`,
        undefined,
        undefined,
        "Failed to fetch template.",
    );
    const template = templateResponse.data;

    const [currentStep, setCurrentStep] = React.useState<TemplateWizardStep>(
        TemplateWizardStep.ConfigureVariables,
    );

    const reconfiguredIntegration: Process | undefined =
        location.state?.process;

    const [processId, setProcessId] = React.useState<number | undefined>(
        reconfiguredIntegration?.processId,
    );

    const [integrationName, setIntegrationName] = React.useState<string>(
        reconfiguredIntegration?.name ?? "",
    );

    const [processVariables, setProcessVariables] =
        React.useState<ProcessVariable[]>();

    const [manualTriggerParameters, setManualTriggerParameters] =
        React.useState<Record<string, string>>();

    const [deployComment, setDeployComment] = React.useState<string>("");

    const reconfiguredIntegrationDetailsFetch = useQuery<ProcessDetails>(
        `${PROCESSES_APIROUTE}/${reconfiguredIntegration?.processId}/details`,
        undefined,
        { enabled: !!reconfiguredIntegration },
        "Failed to fetch integration details.",
    );

    const initialProcessVariables = React.useMemo(() => {
        if (
            !reconfiguredIntegrationDetailsFetch.isLoading &&
            reconfiguredIntegrationDetailsFetch.data
        ) {
            return reconfiguredIntegrationDetailsFetch.data.processVariables;
        }

        if (
            !reconfiguredIntegration &&
            !templateResponse.isLoading &&
            templateResponse.data
        ) {
            return templateResponse.data.processVariables;
        }
    }, [
        reconfiguredIntegrationDetailsFetch.isLoading,
        reconfiguredIntegrationDetailsFetch.data,
        reconfiguredIntegration,
        templateResponse.isLoading,
        templateResponse.data,
    ]);

    React.useEffect(() => {
        if (!processVariables && initialProcessVariables) {
            setProcessVariables(initialProcessVariables);
        }
    }, [processVariables, initialProcessVariables]);

    React.useEffect(() => {
        if (manualTriggerParameters) {
            return;
        }
        if (templateResponse.isFetched && templateResponse.data) {
            setManualTriggerParameters(
                getInitialManualTriggerParameters(
                    templateResponse.data?.manualTriggerParameters,
                ),
            );
        }
    }, [templateResponse, manualTriggerParameters]);

    const runTestExecutionMutation = usePostMutation<DeployedProcess>(
        "processes/trial-run-execute",
        {
            name: integrationName,
            processVariables: processVariables,
            triggerParameters: manualTriggerParameters,
            templateId: templateId,
            processId: processId,
        },
        undefined,
        "Failed to run process trial execution.",
    );

    React.useEffect(() => {
        if (
            !runTestExecutionMutation.isLoading &&
            runTestExecutionMutation.data
        ) {
            setProcessId(runTestExecutionMutation.data.processId);
        }
    }, [runTestExecutionMutation.isLoading, runTestExecutionMutation.data]);

    const deployToProductionMutation = usePostMutation<DeployedProcess>(
        "processes/deploy-production",
        {
            name: integrationName,
            processVariables: processVariables,
            deploymentDescription: deployComment,
            templateId: templateId,
            processId: processId,
        },
        undefined,
        "Failed to deploy process to production.",
    );

    React.useEffect(() => {
        if (
            !deployToProductionMutation.isLoading &&
            deployToProductionMutation.data
        ) {
            setProcessId(deployToProductionMutation.data.processId);
            reconfiguredIntegrationDetailsFetch.refetch();
        }
    }, [
        deployToProductionMutation.isLoading,
        deployToProductionMutation.data,
        reconfiguredIntegrationDetailsFetch,
    ]);

    const isValidBasicConfiguration = React.useMemo(() => {
        const validName = integrationName.trim().length > 0;
        const validProcessVariableValues = processVariables
            ? processVariables.every((variable) => variable.value.length > 0)
            : true;
        return validName && validProcessVariableValues;
    }, [integrationName, processVariables]);

    const handleChangeIntegrationName = (nextValue: string) =>
        setIntegrationName(nextValue);

    const handleChangeProcessVariable = (
        variableName: string,
        variableValue: string,
    ) => {
        // update processVariables with new value
        const updatedProcessVariables = processVariables?.map((variable) => {
            if (variable.name === variableName) {
                return { ...variable, value: variableValue };
            }
            return variable;
        });
        setProcessVariables(updatedProcessVariables);
    };

    const handleChangeManualTriggerParameter = (
        parameterName: string,
        value: string,
    ) => {
        setManualTriggerParameters({
            ...manualTriggerParameters,
            [parameterName]: value,
        });
    };

    const handleChangeStep = (nextStep: TemplateWizardStep) => {
        setCurrentStep(nextStep);
    };

    const handleExecuteTrialRun = () => {
        runTestExecutionMutation.mutate();
    };

    const handleDeployToProduction = () => deployToProductionMutation.mutate();

    const breadcrumbs: Breadcrumb[] = [
        {
            label: "Integration catalog",
            href: INTEGRATION_CATALOG_BASE_PATH,
        },
        {
            label: "Template details",
            href: `${INTEGRATION_CATALOG_BASE_PATH}/${templateId}`,
        },
        {
            label: "Setup wizard",
            href: `${INTEGRATION_CATALOG_BASE_PATH}/${templateId}/setup`,
        },
    ];

    const steps: Step[] = React.useMemo(() => {
        return [
            {
                label: "Configure Variables",
                value: TemplateWizardStep.ConfigureVariables,
                selected: currentStep === TemplateWizardStep.ConfigureVariables,
                disabled: false,
            },
            {
                label: "Test Integration",
                value: TemplateWizardStep.TestIntegration,
                selected: currentStep === TemplateWizardStep.TestIntegration,
                disabled: !isValidBasicConfiguration,
            },
            {
                label: "Deploy Integration",
                value: TemplateWizardStep.DeployIntegration,
                selected: currentStep === TemplateWizardStep.DeployIntegration,
                disabled: !isValidBasicConfiguration,
            },
        ];
    }, [currentStep, isValidBasicConfiguration]);

    const handleStepperChange = (step: TemplateWizardStep) => {
        setCurrentStep(step);
        runTestExecutionMutation.reset();
    };

    const renderStep = () => {
        if (!template) {
            return <div>Template could not be loaded</div>;
        }

        switch (currentStep) {
            case TemplateWizardStep.ConfigureVariables:
                return (
                    <ConfigureIntegrationStep
                        templateId={templateId}
                        integrationName={integrationName}
                        integrationNameChangeDisabled={
                            !!reconfiguredIntegration
                        }
                        originalProcessVariables={processVariables}
                        processTags={template.processTags}
                        isValidBasicConfiguration={isValidBasicConfiguration}
                        onChangeIntegrationName={handleChangeIntegrationName}
                        onChangeProcessVariable={handleChangeProcessVariable}
                        onChangeStep={handleChangeStep}
                    />
                );
            case TemplateWizardStep.TestIntegration:
                return (
                    <TestIntegrationStep
                        hasManualTrigger={template.hasManualTrigger}
                        manualTriggerParameters={
                            template.manualTriggerParameters
                        }
                        runTestExecutionMutation={runTestExecutionMutation}
                        onRunTrialExecution={handleExecuteTrialRun}
                        onChangeManualTriggerParameter={
                            handleChangeManualTriggerParameter
                        }
                        onChangeStep={handleChangeStep}
                    />
                );
            case TemplateWizardStep.DeployIntegration:
                return (
                    <DeployIntegrationStep
                        isDeploymentSuccessful={
                            deployToProductionMutation.isSuccess
                        }
                        isDeploymentLoading={
                            deployToProductionMutation.isLoading
                        }
                        isDeploymentError={deployToProductionMutation.isError}
                        deployComment={deployComment}
                        onDeployToProduction={handleDeployToProduction}
                        onChangeDeployComment={setDeployComment}
                        onChangeStep={handleChangeStep}
                    />
                );
            default:
                return <span>Could not load step {currentStep}</span>;
        }
    };

    const renderProductionDeploymentModal = () => {
        return (
            <Modal
                closeOnBackdropClick={false}
                open={true}
                title="Deploying selected Process to production"
            >
                <S.TemplateWizardProductionDeploymentModalContainer>
                    {deployToProductionMutation.isLoading && (
                        <>
                            <S.GridContainer
                                $gridTemplateColumns="auto auto"
                                $justifyContent="start"
                                $gap="1.563rem"
                            >
                                <Text tag="h3">Deploying the Process...</Text>
                                <LoadingSpinner />
                            </S.GridContainer>
                            <Button disabled={true} variant="bordered">
                                OK
                            </Button>
                        </>
                    )}
                    {deployToProductionMutation.isSuccess && (
                        <>
                            <Text tag="h3">Process deployed successfully!</Text>
                            <Button
                                type="link"
                                href={INTEGRATIONS_BASE_PATH}
                                variant="bordered"
                            >
                                OK
                            </Button>
                        </>
                    )}
                    {deployToProductionMutation.isError && (
                        <Text tag="h3">
                            An error occurred while deploying the Process
                        </Text>
                    )}
                </S.TemplateWizardProductionDeploymentModalContainer>
            </Modal>
        );
    };

    return (
        <>
            <S.TemplateDetailsPageContainer>
                <ContainerWithLoadingSpinner
                    style={{ minHeight: "10rem" }}
                    loading={
                        templateResponse.isLoading ||
                        templateResponse.isFetching
                    }
                >
                    <Breadcrumbs breadcrumbs={breadcrumbs} />
                    <S.ContentContainer>
                        {templateResponse.isLoading && (
                            <Text tag="h2">Loading template details...</Text>
                        )}

                        {!templateResponse.isLoading && template && (
                            <>
                                <Text tag="h2">{template.name}</Text>
                                <Stepper
                                    steps={steps}
                                    onChange={handleStepperChange}
                                />
                                {renderStep()}
                            </>
                        )}

                        {!templateResponse.isLoading && !template && (
                            <TitleWithArrowLink
                                href={INTEGRATION_CATALOG_BASE_PATH}
                                title="Template could not be loaded"
                            />
                        )}
                    </S.ContentContainer>
                </ContainerWithLoadingSpinner>
            </S.TemplateDetailsPageContainer>

            {(deployToProductionMutation.isLoading ||
                deployToProductionMutation.isSuccess) && (
                <>{renderProductionDeploymentModal()}</>
            )}
        </>
    );
};

const getInitialManualTriggerParameters = (
    manualTriggerParameters?: Parameter[],
): Record<string, string> => {
    if (!manualTriggerParameters) {
        return {};
    }

    // Create a record with the parameter name as key and the default value as value
    return manualTriggerParameters.reduce(
        (acc, parameter) => {
            acc[parameter.name] = parameter.nonStringifiedDefaultValue;
            return acc;
        },
        {} as Record<string, string>,
    );
};

export interface ChangeStepProps {
    onChangeStep: (nextStep: TemplateWizardStep) => void;
}

export enum TemplateWizardStep {
    ConfigureVariables = "ConfigureVariables",
    TestIntegration = "TestIntegration",
    DeployIntegration = "DeployIntegration",
}

export default TemplateWizard;
