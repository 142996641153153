/* eslint-disable @typescript-eslint/no-explicit-any */
import { getFillColor, getStrokeColor } from "bpmn-js/lib/draw/BpmnRenderUtil";

import { CustomRendererBase } from "./CustomRendererBase";

export default class DmnCustomRenderer extends CustomRendererBase {
    private dmnElementIds: Set<string>;

    constructor(eventBus: any, config: any, styles: any, pathMap: any) {
        super(eventBus, config, styles, pathMap);
        this.dmnElementIds = new Set();
    }

    static $inject = ["eventBus", "config", "styles", "pathMap"];

    setDmnElementIds(dmnElementIds: Set<string>) {
        this.dmnElementIds = dmnElementIds;
    }

    canRender(element: any) {
        return this.dmnElementIds.has(element.id);
    }

    drawShape(parentGfx: any, element: any): any {
        const customGroup = this.createBasicShape();
        this.svgClasses(customGroup).add("dmn-element");

        this.drawRect(customGroup, element.width, element.height, 8, 0, {
            strokeWidth: 2,
            stroke: getStrokeColor(element),
            fill:
                getFillColor(element) === "white"
                    ? this.defaultFillColor
                    : getFillColor(element),
        });

        this.renderDmnTaskIcon(customGroup, element);
        this.svgAppend(parentGfx, customGroup);

        return customGroup;
    }

    renderDmnTaskIcon = (parentGfx: any, element: any) => {
        const abspos = {
            x: 85,
            y: 70,
        };
        const strokeColor = "#454B4C";
        const mainFillColor =
            getFillColor(element) === "white"
                ? this.defaultFillColor
                : getFillColor(element);
        const headerFillColor = "#B3CBCB";
        const mainPathData = this.pathMap.getScaledPath(
            "TASK_TYPE_BUSINESS_RULE_MAIN",
            { abspos: abspos },
        );
        this.drawPath(parentGfx, mainPathData, {
            strokeWidth: 1,
            stroke: strokeColor,
            fill: mainFillColor,
        });

        const headerPathData = this.pathMap.getScaledPath(
            "TASK_TYPE_BUSINESS_RULE_HEADER",
            { abspos: abspos },
        );
        this.drawPath(parentGfx, headerPathData, {
            strokeWidth: 1,
            stroke: strokeColor,
            fill: headerFillColor,
        });

        this.renderLabel(parentGfx, element.businessObject.name, {
            box: {
                width: element.width,
                height: element.height,
            },
            align: "center-middle",
        });
    };
}
