import * as React from "react";

import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";
import Modal from "components/shared/Modal";
import Button from "components/shared/Button";
import Text from "components/shared/Text";
import Accordion from "components/shared/Accordion";
import Checkbox from "components/shared/Checkbox";
import LoadingSpinner from "components/shared/LoadingSpinner";

import { Organization } from "model/Organization";

interface MigrateOrganizationProcessesModalProps {
    organization: Organization;
    previousProdAgentGroup: string;
    onRevertProdAgentGroupChangeClick: () => void;
    onClose: () => void;
}

const MigrateOrganizationProcessesModal: React.FC<
    MigrateOrganizationProcessesModalProps
> = (props) => {
    const [isMigrationConfirmed, setIsMigrationConfirmed] =
        React.useState(false);

    const postDeploy = useDeferredMutation(
        "POST",
        undefined,
        "Failed to deploy processes",
    );

    const postUndeploy = useDeferredMutation(
        "POST",
        undefined,
        "Failed to undeploy processes",
    );

    const handleMigrateClick = () => {
        if (isMigrationConfirmed) {
            postDeploy.mutate({
                url: "/processes/deploy-organization-processes",
                body: {
                    organizationId: props.organization.id,
                    fromAgentGroupName: props.previousProdAgentGroup,
                    toAgentGroupName: props.organization.prodAgentGroup,
                },
            });
        }
    };

    React.useEffect(() => {
        if (postDeploy.isSuccess) {
            postUndeploy.mutate({
                url: "/processes/undeploy-organization-processes",
                body: {
                    organizationId: props.organization.id,
                    agentGroupName: props.previousProdAgentGroup,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [postDeploy.isSuccess]);

    const isLoading = postDeploy.isLoading || postUndeploy.isLoading;

    const isSuccess = postDeploy.isSuccess && postUndeploy.isSuccess;

    const migrateButtonDisabled = isLoading || !isMigrationConfirmed;

    return (
        <Modal
            title="Organization’s production environment Agent Group changed"
            onClose={props.onClose}
            closeOnBackdropClick={false}
            open
        >
            <S.ConfigureModalContainer>
                <Checkbox
                    label={`I confirm that Processes will be removed from ${props.previousProdAgentGroup} and deployed to ${props.organization.prodAgentGroup}`}
                    checked={isMigrationConfirmed}
                    onChange={() => setIsMigrationConfirmed((prev) => !prev)}
                    disabled={isLoading || isSuccess}
                />

                <Accordion>
                    <Text tag="p" slot="title">
                        Deploy requirements
                    </Text>

                    <ul style={{ listStyle: "disc", paddingLeft: "1.25rem" }}>
                        <li>
                            All used Environment variables need to have values
                            set.
                        </li>
                        <li>
                            All used Subprocesses need to be deployed to the
                            target Agent group/Environment.
                        </li>
                        <li>
                            Process Target Framework needs to match Agent group
                            framework.
                        </li>
                    </ul>
                </Accordion>

                {isLoading && (
                    <S.GridContainer
                        $gridTemplateColumns="auto auto"
                        $justifyContent="start"
                        $gap="1.563rem"
                    >
                        <Text tag="h3">Migrating processes...</Text>
                        <LoadingSpinner />
                    </S.GridContainer>
                )}

                {isSuccess && (
                    <Text tag="h3">Processes migrated succesfully!</Text>
                )}

                {postDeploy.isError && (
                    <Text tag="h3">{`Failed to deploy processes to ${props.organization.prodAgentGroup}. Manual review may be needed. Contact integration developer.`}</Text>
                )}

                {postUndeploy.isError && (
                    <Text tag="h3">{`Failed to remove processes from ${props.previousProdAgentGroup}. Manual review may be needed. Contact integration developer.`}</Text>
                )}

                <S.ConfigureModalActionButtonContainer
                    style={{ justifyContent: "flex-end" }}
                >
                    {!isSuccess && (
                        <>
                            <Button
                                variant="bordered"
                                onClick={
                                    props.onRevertProdAgentGroupChangeClick
                                }
                                disabled={isLoading}
                            >
                                Revert
                            </Button>

                            <Button
                                onClick={handleMigrateClick}
                                disabled={migrateButtonDisabled}
                            >
                                Migrate
                            </Button>
                        </>
                    )}

                    {isSuccess && <Button onClick={props.onClose}>OK</Button>}
                </S.ConfigureModalActionButtonContainer>
            </S.ConfigureModalContainer>
        </Modal>
    );
};

export default MigrateOrganizationProcessesModal;
