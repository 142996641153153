import * as React from "react";
import * as S from "@styled";
import {
    ChangeStepProps,
    TemplateWizardStep,
} from "../../../pages/integrationCatalog/TemplateWizard";
import { Parameter } from "model/Parameter";
import Button, { SaveButton } from "components/shared/Button";
import Text from "components/shared/Text";
import Input from "components/shared/Input";
import { UseMutationResult } from "react-query";
import { DeployedProcess } from "model/process/DeployedProcess";
import useDeferredMutation from "hooks/useDeferredMutation";
import { ProcessInstanceBase } from "model/process/ProcessInstance";
import AccordionGroup from "components/shared/AccordionGroup";
import Accordion from "components/shared/Accordion";
import DescriptionList, {
    DescriptionListItem,
} from "components/shared/DescriptionList";
import Time from "shared/Time";
import Tooltip from "components/shared/Tooltip";

const TestIntegrationStep: React.FC<TestIntegrationProps> = (props) => {
    const manualTriggerParametersElements = props.manualTriggerParameters?.map(
        (parameter) => {
            return (
                <React.Fragment key={parameter.name}>
                    <Input
                        label={parameter.name}
                        value={parameter.nonStringifiedDefaultValue || ""}
                        onChange={(e: string) =>
                            props.onChangeManualTriggerParameter(
                                parameter.name,
                                e,
                            )
                        }
                    />
                    {parameter.description && (
                        <Text tag="p">{parameter.description}</Text>
                    )}
                </React.Fragment>
            );
        },
    );

    return (
        <S.TemplateWizardPageContainer>
            {manualTriggerParametersElements}
            <S.TemplateWizardExecuteButtonContainer>
                <SaveButton
                    variant="bordered"
                    onClick={props.onRunTrialExecution}
                    disabled={!props.hasManualTrigger}
                    loading={props.runTestExecutionMutation.isLoading}
                    success={props.runTestExecutionMutation.isSuccess}
                    error={props.runTestExecutionMutation.isError}
                >
                    Execute trial run
                </SaveButton>
                <Tooltip placement="top">
                    Deploys the Process to the testing Agent group and executes
                    it.
                </Tooltip>
            </S.TemplateWizardExecuteButtonContainer>

            <TestRunDetailsTable
                runTestExecutionMutation={props.runTestExecutionMutation}
            />

            <S.TemplateWizardStepButtonsContainer>
                <Button
                    variant="bordered"
                    onClick={() =>
                        props.onChangeStep(
                            TemplateWizardStep.ConfigureVariables,
                        )
                    }
                >
                    Back
                </Button>
                <Button
                    variant="bordered"
                    onClick={() =>
                        props.onChangeStep(TemplateWizardStep.DeployIntegration)
                    }
                >
                    Continue
                </Button>
            </S.TemplateWizardStepButtonsContainer>
        </S.TemplateWizardPageContainer>
    );
};

const TestRunDetailsTable: React.FC<TestRunDetailsTableProps> = (props) => {
    const deployedTestProcess = props.runTestExecutionMutation.data;
    const deployedProcessGuid = deployedTestProcess?.processGuid;

    const [latestExecution, setLatestExecution] =
        React.useState<ProcessInstanceBase>();
    const [restExecutions, setRestExecutions] =
        React.useState<ProcessInstanceBase[]>();

    const processInstances = useDeferredMutation<ProcessInstanceBase[]>(
        "GET",
        undefined,
        "Failed to fetch available process trial run instances.",
    );

    const fetchInstances = () => {
        if (deployedProcessGuid !== undefined) {
            processInstances.reset();
            processInstances.mutate({
                url: `processes/trial-run-instances?processGuid=${deployedProcessGuid}`,
            });
        }
    };

    React.useEffect(() => {
        fetchInstances();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deployedProcessGuid]);

    React.useEffect(() => {
        if (processInstances.isError) {
            setTimeout(() => fetchInstances(), 500);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processInstances.isError]);

    React.useEffect(() => {
        if (processInstances.data) {
            const processInstanceData = processInstances.data;
            setLatestExecution(processInstanceData[0]);
            setRestExecutions(processInstanceData.slice(1));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processInstances.data]);

    return (
        <>
            {props.runTestExecutionMutation.isLoading &&
                !processInstances.isLoading && (
                    <Text tag="h3">
                        Deploying Process to test Agent group and executing
                        Process...
                    </Text>
                )}

            {props.runTestExecutionMutation.isSuccess &&
                processInstances.isLoading && (
                    <Text tag="h3">
                        Process was deployed and execution message was sent,
                        waiting for results...
                    </Text>
                )}

            {latestExecution && (
                <>
                    <Text tag="h3">Test result</Text>

                    <DescriptionList>
                        <div className="two-column-grid">
                            <DescriptionListItem
                                dt="State"
                                dd={latestExecution.state}
                            />
                            <DescriptionListItem
                                dt="Execution ID"
                                dd={latestExecution.executionId}
                            />
                            <DescriptionListItem
                                dt="Start time"
                                dd={Time.toDetailedLocalTime(
                                    latestExecution.startTimeUtc,
                                )}
                            />
                            <DescriptionListItem
                                dt="End time"
                                dd={Time.toDetailedLocalTime(
                                    latestExecution.endTimeUtc,
                                )}
                            />
                        </div>
                    </DescriptionList>
                </>
            )}

            {restExecutions && restExecutions.length > 0 && (
                <S.TemplateWizardResultHistoryContainer>
                    <Text tag="h3">Test result history</Text>
                    <AccordionGroup>
                        {restExecutions.map((processInstance, index) => {
                            return (
                                <Accordion key={index}>
                                    <h3 slot="title">
                                        Test result{" "}
                                        {Time.toDetailedLocalTime(
                                            processInstance.startTimeUtc,
                                        )}
                                    </h3>
                                    <DescriptionList>
                                        <div className="two-column-grid">
                                            <DescriptionListItem
                                                dt="State"
                                                dd={processInstance.state}
                                            />
                                            <DescriptionListItem
                                                dt="Execution ID"
                                                dd={processInstance.executionId}
                                            />
                                            <DescriptionListItem
                                                dt="Start time"
                                                dd={Time.toDetailedLocalTime(
                                                    processInstance.startTimeUtc,
                                                )}
                                            />
                                            <DescriptionListItem
                                                dt="End time"
                                                dd={Time.toDetailedLocalTime(
                                                    processInstance.endTimeUtc,
                                                )}
                                            />
                                        </div>
                                    </DescriptionList>
                                    {processInstance.exception && (
                                        <Text tag="p">
                                            Exception:{" "}
                                            {processInstance.exception}
                                        </Text>
                                    )}
                                </Accordion>
                            );
                        })}
                    </AccordionGroup>
                </S.TemplateWizardResultHistoryContainer>
            )}
        </>
    );
};

interface TestIntegrationProps extends ChangeStepProps {
    hasManualTrigger: boolean;
    manualTriggerParameters?: Parameter[];
    runTestExecutionMutation: TestDeploymentMutationResult;
    onRunTrialExecution: () => void;
    onChangeManualTriggerParameter: (
        parameterName: string,
        value: string,
    ) => void;
}

interface TestRunDetailsTableProps {
    runTestExecutionMutation: TestDeploymentMutationResult;
}

export type TestDeploymentMutationResult = UseMutationResult<
    DeployedProcess,
    unknown,
    void | Record<string, unknown>,
    unknown
>;

export default TestIntegrationStep;
