/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseRenderer from "diagram-js/lib/draw/BaseRenderer";
import EventBus from "diagram-js/lib/core/EventBus";
import PathMap from "bpmn-js/lib/draw/PathMap";
import TextUtil from "diagram-js/lib/util/Text";
import { append, attr, classes, create } from "tiny-svg";

export class CustomRendererBase extends BaseRenderer {
    protected textUtil: TextUtil;
    protected defaultFillColor: string;
    protected computeStyle: any;
    protected pathMap: any;

    constructor(
        eventBus: EventBus,
        config: any,
        styles: any,
        pathMap: PathMap,
    ) {
        super(eventBus, 1500);
        this.textUtil = new TextUtil({
            style: { fontFamily: "Arial, sans-serif", fontSize: "12px" },
            size: { width: 100, height: 100 },
        });
        this.defaultFillColor =
            config?.bpmnRenderer?.defaultFillColor || "#FFFFFF";
        this.computeStyle = styles.computeStyle;
        this.pathMap = pathMap;
    }

    // Original from bpmn-js/lib/draw/BpmnRenderer.js line 306 (also frends.acc CustomRenderer.ts)
    drawPath = (parentGfx: any, d: any, attrs: any) => {
        attrs = this.computeStyle(attrs, ["no-fill"], {
            strokeWidth: 2,
            stroke: "black",
        });
        const path = this.svgCreate("path");
        this.svgAttr(path, { d: d });
        this.svgAttr(path, attrs);
        this.svgAppend(parentGfx, path);
        return path;
    };

    // Original from bpmn-js/lib/draw/BpmnRenderer.js line 233 (also frends.acc CustomRenderer.ts)
    drawRect = (
        parentGfx: any,
        width: any,
        height: any,
        r: any,
        offset: any,
        attrs: any,
    ) => {
        offset = offset || 0;

        attrs = this.computeStyle(attrs, {
            stroke: "black",
            strokeWidth: 2,
            fill: this.defaultFillColor,
        });

        const rect = this.svgCreate("rect");
        this.svgAttr(rect, {
            x: offset,
            y: offset,
            width: width - offset * 2,
            height: height - offset * 2,
            rx: r,
            ry: r,
        });
        this.svgAttr(rect, attrs);

        this.svgAppend(parentGfx, rect);

        return rect;
    };

    // Original from bpmn-js/lib/draw/BpmnRenderer.js line 394 (also frends.acc CustomRenderer.ts)
    renderLabel = (parentGfx: any, label: string | undefined, options: any) => {
        const text = this.textUtil.createText(label || "", options);
        this.svgClasses(text).add("djs-label");
        this.svgAppend(parentGfx, text);
        return text;
    };

    protected svgAppend = (parentGfx: any, element: any) => {
        append(parentGfx, element);
    };

    protected svgAttr = (element: any, attrs: any) => {
        attr(element, attrs);
    };

    protected svgClasses = (element: any) => {
        return classes(element);
    };

    protected svgCreate = (tagName: string) => {
        return create(tagName);
    };

    protected createBasicShape(): any {
        const customGroup = this.svgCreate("g");
        this.svgClasses(customGroup).add("djs-element");
        this.svgClasses(customGroup).add("djs-shape");
        return customGroup;
    }
}
