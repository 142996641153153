import * as React from "react";

import { AppContext } from "App";

import * as S from "@styled";

import Modal from "components/shared/Modal";
import { SimpleButton } from "components/shared/Button";

import Cookie from "shared/Cookie";

const CookieConsentModal: React.FC = () => {
    const { state } = React.useContext(AppContext);
    const cookieConsentName = "cookieconsent_status";
    const noCookieConsent = !Cookie.getCookieValue(cookieConsentName);

    const setCookieConsent = (value: "allow" | "deny") => {
        Cookie.setCookie(cookieConsentName, value, 365, location.hostname, "/");

        location.reload();
    };

    const handleDenyButtonClick = () => {
        // Delete AppInsights tracking cookies
        Cookie.removeCookie("ai_session");
        Cookie.removeCookie("ai_user");

        setCookieConsent("deny");
    };

    const handleAllowButtonClick = () => setCookieConsent("allow");

    return (
        <Modal
            title="Please accept Cookies"
            open={state.showCookiePolicy || noCookieConsent}
            hideCloseButton
            closeOnBackdropClick={false}
        >
            <S.CookieConsentModalContent>
                <p>
                    We use cookies to improve your experience on the Frends
                    platform. A cookie is a small piece of data we store on your
                    device or browser when you visit the Frends platform. This
                    piece of data allows us to store your user settings, track
                    certain activities you perform on the platform and to
                    personalize your experience by offering for eg. automated
                    on-boarding guidance. For our full Cookie Policy refer to:{" "}
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://frends.com/legal/cookie-policy"
                    >
                        https://frends.com/legal/cookie-policy
                    </a>
                    .
                </p>

                <p>If you Allow Cookies:</p>

                <ol>
                    <li>
                        We use Microsoft Application Insights, a service for
                        collecting, transmitting, storing and analyzing
                        diagnostic and usage data. This helps us understand how
                        our application is performing and where improvements are
                        needed. Application Insights collects data such as
                        response times of requests, failure rates, dependency
                        rates and exceptions. This data is used to monitor the
                        performance and reliability of our application, not to
                        track individual users behavior.
                    </li>
                </ol>

                <p>
                    We will not track any of your activities if you choose NOT
                    to accept cookies.
                </p>

                <div>
                    <SimpleButton
                        onClick={handleDenyButtonClick}
                        variant="bordered"
                    >
                        Deny
                    </SimpleButton>

                    <SimpleButton onClick={handleAllowButtonClick}>
                        Allow
                    </SimpleButton>
                </div>
            </S.CookieConsentModalContent>
        </Modal>
    );
};

export default CookieConsentModal;
