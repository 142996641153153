import { UseMutationResult, useMutation } from "react-query";
import Utilities from "shared/utilities";
import useHandleMutationError from "./useHandleMutationError";
import { FetchError } from "model/FetchError";

const usePostMutation = <T = string, BodyType = Record<string, unknown>>(
    url: string,
    initialBody: BodyType,
    onSuccess?: () => void,
    errorMsg?: string,
): UseMutationResult<
    T,
    FetchError,
    Record<string, unknown> | void,
    unknown
> => {
    const mutation = useMutation<
        T,
        FetchError,
        Record<string, unknown> | void,
        unknown
    >({
        mutationFn: (body: Record<string, unknown>) =>
            Utilities.fetchBase(url, "POST", body || initialBody),
        onSuccess,
    });

    useHandleMutationError(mutation, errorMsg);

    return mutation;
};

export const usePostFileMutation = async (
    url: string,
    file?: File,
    onSuccess?: () => void,
    errorMsg?: string,
): Promise<UseMutationResult<string, FetchError, void>> => {
    const mutation = useMutation<string, FetchError, void>({
        mutationFn: () => Utilities.fetchBase(url, "POST", undefined, file),
        onSuccess,
    });

    useHandleMutationError(mutation, errorMsg);

    return mutation;
};

export default usePostMutation;
