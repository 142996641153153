import * as React from "react";
import { useNavigate } from "react-router-dom";

import useUserData from "hooks/useUserData";
import usePutMutation from "hooks/usePutMutation";
import useDeferredMutation from "hooks/useDeferredMutation";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import IntegrationFilters from "components/integrations/IntegrationFilters";
import Divider from "components/shared/Divider";
import Button, {
    CloseButton,
    DropdownButton,
    SaveButton,
} from "components/shared/Button";
import Toggle from "components/shared/Toggle";
import Link from "components/shared/Link";
import ConfirmDeleteModal from "components/settings/ConfirmDeleteModal";

import { OrganizationBase } from "model/Organization";
import { Process, ProcessCounts } from "model/process/Process";

import {
    INTEGRATION_CATALOG_BASE_PATH,
    INTEGRATIONS_BASE_PATH,
} from "AppRoutes";
import { AppContext } from "App";
import { Types } from "reducers/AppReducer";

export const PROCESSES_APIROUTE = "processes";

const MyIntegrations: React.FC = () => {
    const { dispatch } = React.useContext(AppContext);
    const { selectedOrganization } = useUserData();
    const [
        openConfirmDeleteModalForProcessId,
        setOpenConfirmDeleteModalForProcessId,
    ] = React.useState<number | undefined>();

    const deferredCountsFetch = useDeferredMutation<ProcessCounts>("GET");

    const deferredProcessesFetch = useDeferredMutation<Process[]>("GET");

    const fetchProcessData = () => {
        deferredCountsFetch.reset();
        deferredCountsFetch.mutate({
            url: `${PROCESSES_APIROUTE}/all-counts`,
        });

        deferredProcessesFetch.reset();
        deferredProcessesFetch.mutate({
            url: `${PROCESSES_APIROUTE}/organizations/${selectedOrganization!.id}`,
        });
    };

    React.useEffect(() => {
        fetchProcessData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedOrganization]);

    React.useEffect(() => {
        if (deferredProcessesFetch.data) {
            dispatch({
                type: Types.SET_INTEGRATIONS,
                payload: {
                    integrations: {
                        processes: deferredProcessesFetch.data,
                    },
                },
            });
        }
    }, [deferredProcessesFetch.data, dispatch]);

    const deferredProcessDelete = useDeferredMutation(
        "DELETE",
        () => {
            setOpenConfirmDeleteModalForProcessId(undefined);
            fetchProcessData();
        },
        "Failed to delete process.",
    );

    const handleDeleteProcess = () => {
        deferredProcessDelete.mutate({
            url: `${PROCESSES_APIROUTE}/${openConfirmDeleteModalForProcessId}`,
        });
    };

    const handleDeleteClick = (processId: number) =>
        setOpenConfirmDeleteModalForProcessId(processId);

    return (
        <div>
            <S.MyIntegrationsTitleContainer>
                <Text tag="h1">My integrations</Text>
            </S.MyIntegrationsTitleContainer>

            <ProcessesExecutionCounts
                counts={deferredCountsFetch.data}
                isLoading={deferredCountsFetch.isLoading}
            />

            <ProcessList
                organization={selectedOrganization!}
                isLoading={deferredProcessesFetch.isLoading}
                onProcessDeleteClick={handleDeleteClick}
            />

            {openConfirmDeleteModalForProcessId !== undefined && (
                <ConfirmDeleteModal
                    title="Do you really want to delete this process?"
                    onClose={() =>
                        setOpenConfirmDeleteModalForProcessId(undefined)
                    }
                    onDelete={handleDeleteProcess}
                    deleting={deferredProcessDelete.isLoading}
                    deleteError={deferredProcessDelete.isError}
                />
            )}
        </div>
    );
};

interface ProcessesExecutionCountsProps {
    isLoading: boolean;
    counts?: ProcessCounts;
}

const ProcessesExecutionCounts: React.FC<ProcessesExecutionCountsProps> = (
    props,
) => {
    const getSuccessRatio = () => {
        if (successes === undefined || executions === undefined) {
            return undefined;
        }

        // Cant calculate ratio if both are 0
        if (successes === 0 && executions === 0) {
            return undefined;
        }

        return `${Number((successes / executions) * 100).toFixed(1)}%`;
    };

    const counts = props.counts;
    const processes = counts?.processesCount;
    const executions = counts?.totalExecutionCount;
    const errors = counts?.failedExecutionCount;
    const successes = counts?.successfulExecutionCount;
    const successRatio = getSuccessRatio();

    return (
        <S.MyIntegrationsKPIContainer>
            <Text>Last 7 days</Text>

            <ContainerWithLoadingSpinner
                loading={props.isLoading}
                style={{ minHeight: "7.438rem" }}
            >
                <CountLarge value={processes} label="Processes" />

                <CountLarge value={executions} label="Executions" />

                <CountLarge value={errors} label="Errors" />

                <CountLarge value={successRatio} label="Success" />
            </ContainerWithLoadingSpinner>
        </S.MyIntegrationsKPIContainer>
    );
};

interface ProcessListProps {
    organization: OrganizationBase;
    isLoading: boolean;
    onProcessDeleteClick: (processId: number) => void;
}

const ProcessList: React.FC<ProcessListProps> = (props) => {
    const { state } = React.useContext(AppContext);
    const stateProcesses = state.integrations.processes;

    const isListViewStorageKey = "frends_bap_my_integrations_is_list_view";
    const [processes, setProcesses] = React.useState<Process[]>([]);
    const [processTags, setProcessTags] = React.useState<string[]>([]);
    const [tagFilter, setTagFilter] = React.useState<string[]>([]);
    const [searchFilter, setSearchFilter] = React.useState<string>("");
    const initialIsListView =
        String(localStorage.getItem(isListViewStorageKey)) === "true";
    const [isListView, setIsListView] = React.useState(initialIsListView);

    React.useEffect(() => {
        if (stateProcesses) {
            const uniqueTags = Array.from(
                new Set(stateProcesses.flatMap((i) => i.tags)),
            );
            setProcessTags(uniqueTags);
        }
    }, [stateProcesses]);

    React.useEffect(() => {
        const filteredProcesses = stateProcesses?.filter((process) => {
            const nameMatch = searchFilter
                ? process.name
                      .toLowerCase()
                      .includes(searchFilter.toLowerCase())
                : true;
            const processTagsMatch =
                tagFilter.length > 0
                    ? process.tags?.some((tag) => tagFilter.includes(tag))
                    : true;
            return nameMatch && processTagsMatch;
        });
        setProcesses(filteredProcesses ?? []);
    }, [stateProcesses, tagFilter, searchFilter]);

    const handleChangeTagFilter = (nextValues: string[]) =>
        setTagFilter(nextValues);

    const handleChangeSearchFilter = (nextValue: string) =>
        setSearchFilter(nextValue);

    const handleChangeLayout = (next: boolean) => {
        setIsListView(next);
        localStorage.setItem(isListViewStorageKey, String(next));
    };

    return (
        <>
            <IntegrationFilters
                tags={processTags}
                isListView={isListView}
                searchFilter={searchFilter}
                tagFilter={tagFilter}
                onChangeLayout={handleChangeLayout}
                onChangeSearchFilter={handleChangeSearchFilter}
                onChangeTagFilter={handleChangeTagFilter}
            />

            <ContainerWithLoadingSpinner
                loading={props.isLoading}
                style={{ minHeight: "5rem" }}
            >
                {stateProcesses && processes.length > 0 && (
                    <S.MyIntegrationsList $cols={isListView ? 1 : 3}>
                        {processes.map((i, idx) => (
                            <React.Fragment key={idx}>
                                {isListView ? (
                                    <IntegrationsLargeListItem
                                        process={i}
                                        organization={props.organization}
                                        onDeleteClick={
                                            props.onProcessDeleteClick
                                        }
                                    />
                                ) : (
                                    <IntegrationsListItem
                                        process={i}
                                        organization={props.organization}
                                        onDeleteClick={
                                            props.onProcessDeleteClick
                                        }
                                    />
                                )}
                            </React.Fragment>
                        ))}
                    </S.MyIntegrationsList>
                )}

                {!props.isLoading && processes.length === 0 && (
                    <Text tag="h3">No integrations found</Text>
                )}
            </ContainerWithLoadingSpinner>
        </>
    );
};

interface CountProps {
    value?: string | number;
    label: string;
}

const CountLarge: React.FC<CountProps> = ({ value = "-", label }) => (
    <S.MyIntegrationsCountLarge>
        <Text tag="span" size="bold">
            {value}
        </Text>

        <br />

        <Text tag="span" appearance="h2">
            {label}
        </Text>
    </S.MyIntegrationsCountLarge>
);

const Count: React.FC<CountProps> = ({ value = "-", label }) => (
    <S.MyIntegrationsCountLarge>
        <Text tag="span" appearance="h2" size="bold">
            {value}
        </Text>

        <br />

        <Text tag="span" appearance="label">
            {label}
        </Text>
    </S.MyIntegrationsCountLarge>
);

interface IntegrationsListItemProps {
    process: Process;
    organization: OrganizationBase;
    onDeleteClick: (processId: number) => void;
}

const IntegrationsListItem: React.FC<IntegrationsListItemProps> = ({
    process,
    organization,
    onDeleteClick,
}) => {
    const [actionsOpen, setActionsOpen] = React.useState(false);

    const toggleActionsOpen = () => setActionsOpen((prev) => !prev);

    return (
        <S.MyIntegrationsListItem>
            <Text tag="h3">{process.name}</Text>

            <Divider />

            {actionsOpen ? (
                <S.MyIntegrationsListItemActiveToggleContainer>
                    <IntegrationActivateToggle process={process} />

                    <Link
                        type="button"
                        onClick={() => onDeleteClick(process.processId)}
                        text="Delete"
                    />
                </S.MyIntegrationsListItemActiveToggleContainer>
            ) : (
                <S.MyIntegrationsListItemKPIContainer>
                    <Count
                        value={process.totalExecutionCount}
                        label="Executions"
                    />
                    <Count
                        value={process.failedExecutionCount}
                        label="Errors"
                    />
                </S.MyIntegrationsListItemKPIContainer>
            )}

            <S.MyIntegrationsListItemActionsContainer
                $justifyContent={actionsOpen ? "space-evenly" : "space-between"}
            >
                <ViewInstancesLink
                    process={process}
                    organization={organization}
                />

                {actionsOpen ? (
                    <>
                        <ManuallyRunLink
                            process={process}
                            organization={organization}
                        />

                        <IntegrationUpdateButton process={process} />

                        <ReconfigureLink
                            process={process}
                            organization={organization}
                        />

                        <CloseButton
                            title="Hide actions"
                            onClick={toggleActionsOpen}
                        />
                    </>
                ) : (
                    <DropdownButton
                        title="Show actions"
                        onClick={toggleActionsOpen}
                    >
                        Actions
                    </DropdownButton>
                )}
            </S.MyIntegrationsListItemActionsContainer>
        </S.MyIntegrationsListItem>
    );
};

const IntegrationsLargeListItem: React.FC<IntegrationsListItemProps> = ({
    process,
    organization,
    onDeleteClick,
}) => {
    return (
        <S.MyIntegrationsLargeListItem>
            <S.MyIntegrationsLargeListItemTitleContainer>
                <IntegrationActivateToggle process={process} hidelabel />

                <Text tag="h2">{process.name}</Text>
            </S.MyIntegrationsLargeListItemTitleContainer>

            <Text tag="p">{process.description}</Text>

            <S.MyIntegrationsLargeListItemActionContainer>
                <S.MyIntegrationsLargeListItemActionContainer>
                    <ViewInstancesLink
                        process={process}
                        organization={organization}
                    />

                    <ManuallyRunLink
                        process={process}
                        organization={organization}
                    />
                </S.MyIntegrationsLargeListItemActionContainer>

                <S.MyIntegrationsLargeListItemActionContainer>
                    <Link
                        type="button"
                        onClick={() => onDeleteClick(process.processId)}
                        text="Delete"
                    />

                    <IntegrationUpdateButton process={process} />

                    <ReconfigureLink
                        process={process}
                        organization={organization}
                    />
                </S.MyIntegrationsLargeListItemActionContainer>
            </S.MyIntegrationsLargeListItemActionContainer>
        </S.MyIntegrationsLargeListItem>
    );
};

interface IntegrationActivateToggleProps {
    process: Process;
    hidelabel?: boolean;
}

const IntegrationActivateToggle: React.FC<IntegrationActivateToggleProps> = (
    props,
) => {
    const [active, setActive] = React.useState(props.process.triggersActive);

    const activate = useDeferredMutation(
        "PUT",
        undefined,
        "Failed to activate process.",
    );

    React.useEffect(() => {
        if (activate.error) {
            setActive((prev) => !prev);
        }
    }, [activate.error]);

    const toggleActive = () => {
        activate.reset();
        const nextActive = !active;
        activate.mutate({
            url: `${PROCESSES_APIROUTE}/${props.process.deploymentId}/${nextActive ? "disable" : "enable"}`,
        });
        setActive(nextActive);
    };

    return (
        <ContainerWithLoadingSpinner loading={activate.isLoading}>
            <Toggle
                label="Active"
                hidelabel={props.hidelabel}
                ontext="YES"
                offtext="NO"
                checked={active}
                onChange={toggleActive}
            />
        </ContainerWithLoadingSpinner>
    );
};

type IntegrationLinkProps = Omit<IntegrationLinkBaseProps, "href">;

const ViewInstancesLink: React.FC<IntegrationLinkProps> = (props) => (
    <IntegrationLinkBase
        href={`${INTEGRATIONS_BASE_PATH}/${props.process.processId}/instances`}
        process={props.process}
        organization={props.organization}
    >
        View instances
    </IntegrationLinkBase>
);

const ManuallyRunLink: React.FC<IntegrationLinkProps> = (props) => (
    <IntegrationLinkBase
        href={`${INTEGRATIONS_BASE_PATH}/${props.process.processId}/manually-run`}
        process={props.process}
        organization={props.organization}
    >
        Manual run
    </IntegrationLinkBase>
);

const ReconfigureLink: React.FC<IntegrationLinkProps> = (props) => (
    <IntegrationLinkBase
        href={`${INTEGRATION_CATALOG_BASE_PATH}/${props.process.templateId}/setup`}
        process={props.process}
        organization={props.organization}
    >
        Reconfigure
    </IntegrationLinkBase>
);

interface IntegrationLinkBaseProps {
    href: string;
    process: Process;
    organization: OrganizationBase;
}

const IntegrationLinkBase: React.FC<
    React.PropsWithChildren<IntegrationLinkBaseProps>
> = (props) => {
    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        navigate(event.currentTarget.href, {
            state: {
                process: props.process,
                organization: props.organization,
                previousPage: INTEGRATIONS_BASE_PATH,
            },
        });
    };

    return (
        <Button
            type="link"
            variant="bordered"
            onClick={handleClick}
            href={props.href}
        >
            {props.children}
        </Button>
    );
};

const IntegrationUpdateButton: React.FC<{ process: Process }> = ({
    process,
}) => {
    const { dispatch } = React.useContext(AppContext);
    const update = usePutMutation<Process>(
        `${PROCESSES_APIROUTE}/${process.processId}`,
        { templateId: process.templateId },
        undefined,
        "Failed to update process.",
    );

    React.useEffect(() => {
        if (update.data) {
            dispatch({
                type: Types.UPDATE_INTEGRATION,
                payload: {
                    processId: process.processId,
                    updatedProcess: update.data,
                },
            });
        }
    }, [update.data]);

    const handleUpdateClick = () => {
        if (!update.isLoading) {
            update.reset();
            update.mutate();
        }
    };

    return (
        <SaveButton
            variant="bordered"
            onClick={handleUpdateClick}
            loading={update.isLoading}
            success={update.isSuccess}
            error={update.isError}
        >
            Update
        </SaveButton>
    );
};

export default MyIntegrations;
