/* eslint-disable no-empty */
import { useNavigate } from "react-router-dom";

import useUserData from "./useUserData";
import useToaster from "./useToaster";

import Utilities from "shared/utilities";

import { Environment } from "model/ShopConfiguration";
import { Organization } from "model/Organization";

import { SHOPCONFIG_ENVIRONMENTS_API_BASE_PATH } from "AppRoutes";

const useInitialSetupReminder = () => {
    const { isTenantAdmin, selectedOrganization } = useUserData();
    const { toastError } = useToaster();
    const navigate = useNavigate();

    const tryRemindTenantAdminAboutShopConfiguration = async () => {
        if (!isTenantAdmin) {
            return;
        }

        try {
            const environments: Environment[] = await Utilities.fetchBase(
                SHOPCONFIG_ENVIRONMENTS_API_BASE_PATH,
                "GET",
            );
            const hasEnvironmentWithoutSelectedAgentGroup = environments.some(
                (ag) => !ag.selectedAgentGroup,
            );

            if (hasEnvironmentWithoutSelectedAgentGroup) {
                navigate("/settings/shop-configuration");

                toastError(
                    "Please set default run environments for created organizations. These can be customized per organization in organization settings. \n\nYou may also want to configure available integrations and categories.",
                );
            } else {
                tryRemindTenantAdminAboutAssumedInitOrgSettings();
            }
        } catch (_) {}
    };

    const tryRemindTenantAdminAboutAssumedInitOrgSettings = async () => {
        if (!isTenantAdmin) {
            return;
        }

        try {
            // here its assumed that selected organization is the initial organization aka portal admin org
            const organization: Organization = await Utilities.fetchBase(
                `organizations/${selectedOrganization!.id}`,
                "GET",
            );

            if (!organization.testAgentGroup || !organization.prodAgentGroup) {
                navigate(`/settings/organizations/${selectedOrganization!.id}`);

                toastError(
                    "Please configure run environments for your organization's integrations.",
                );
            }
        } catch (_) {}
    };

    return {
        tryRemindTenantAdminAboutShopConfiguration,
        tryRemindTenantAdminAboutAssumedInitOrgSettings,
    };
};

export default useInitialSetupReminder;
