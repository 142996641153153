import * as React from "react";
import * as S from "@styled";
import Viewer from "bpmn-js/lib/NavigatedViewer";
import Canvas from "diagram-js/lib/core/Canvas";
import styled from "styled-components";
import Button from "components/shared/Button";
import DmnCustomRenderer from "./DmnCustomRendered";
import { ProcessElementParameters } from "model/process/ProcessElementParameters";

const BpmnViewer: React.FC<BpmnViewerProps> = (props) => {
    const viewerRef = React.useRef<Viewer>();
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const dmnElementIds = React.useMemo(
        () =>
            new Set(
                props.elementParameters
                    ?.filter((e) => e.type.toLowerCase() === "dmn")
                    .map((e) => e.id) ?? [],
            ),
        [props.elementParameters],
    );

    const importXml = React.useCallback(async () => {
        if (!containerRef.current) {
            return;
        }

        const dmnRendererName = "dmnCustomRenderer";

        if (!viewerRef.current) {
            viewerRef.current = new Viewer({
                container: containerRef.current,
                additionalModules: [
                    {
                        __init__: [dmnRendererName],
                        dmnCustomRenderer: ["type", DmnCustomRenderer],
                    },
                ],
            });
        }

        viewerRef.current
            .get<DmnCustomRenderer>(dmnRendererName)
            .setDmnElementIds(dmnElementIds);

        await viewerRef.current.importXML(props.bpmnXml);
        const canvas = viewerRef.current.get<Canvas>("canvas");
        canvas.zoom("fit-viewport");
    }, [props.bpmnXml, dmnElementIds]);

    React.useEffect(() => {
        if (props.bpmnXml) {
            importXml();
        }
    }, [importXml, props.bpmnXml]);

    const handleResetZoom = () => {
        if (viewerRef.current) {
            const canvas = viewerRef.current.get<Canvas>("canvas");
            canvas.zoom("fit-viewport");
        }
    };

    return (
        <BpmnContainer>
            <S.GridContainer $justifyContent="right">
                <Button onClick={handleResetZoom}>Reset Zoom</Button>
            </S.GridContainer>
            <div ref={containerRef} style={{ height: "400px" }} />
        </BpmnContainer>
    );
};

const BpmnContainer = styled.div`
    .bjs-container {
        *:focus {
            outline: none !important;
        }
    }
`;

interface BpmnViewerProps {
    bpmnXml: string;
    elementParameters?: ProcessElementParameters[];
}

export default BpmnViewer;
