import styled from "styled-components";

export const TemplateWizardPageContainer = styled.div`
    --frends-text--margin: 0 0 1.313rem 0;
    --frends-text-input--container--margin: 0 0 1rem 0;
    --frends-divider--margin: 1rem 0;
`;

export const TemplateWizardExecuteButtonContainer = styled.div`
    display: grid;
    gap: 1rem;
    justify-content: center;
    grid-template-columns: auto auto;
    align-items: center;
`;

export const TemplateWizardStepButtonsContainer = styled.div`
    margin-top: 1.313rem;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
`;

export const TemplateWizardProductionDeploymentModalContainer = styled.div`
    margin-top: 1.5rem;
    display: grid;
    gap: 1rem;
    width: 57.688rem;
    --frends-button--margin: 0 0 0 auto;
`;

export const TemplateWizardResultHistoryContainer = styled.div`
    margin-top: 1.313rem;
`;
