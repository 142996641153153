import * as React from "react";

import useQuery from "hooks/useQuery";
import useDeferredMutation from "hooks/useDeferredMutation";
import useInitialSetupReminder from "hooks/useInitialSetupReminder";

import * as S from "@styled";

import ContainerWithLoadingSpinner from "components/shared/ContainerWithLoadingSpinner";
import Text from "components/shared/Text";
import { SaveButton } from "components/shared/Button";
import Dropdown from "components/shared/Dropdown";

import { Environment } from "model/ShopConfiguration";

import { SHOPCONFIG_ENVIRONMENTS_API_BASE_PATH } from "AppRoutes";

const IntegrationEnvironments: React.FC = () => {
    const { tryRemindTenantAdminAboutAssumedInitOrgSettings } =
        useInitialSetupReminder();

    const fetch = useQuery<Environment[]>(
        SHOPCONFIG_ENVIRONMENTS_API_BASE_PATH,
        undefined,
        undefined,
        "Failed to fetch environments.",
    );
    const [environments, setEnvironments] = React.useState<Environment[]>();

    React.useEffect(() => {
        if (fetch.data) {
            setEnvironments(fetch.data);
        }
    }, [fetch.data]);

    const save = useDeferredMutation(
        "PUT",
        () => tryRemindTenantAdminAboutAssumedInitOrgSettings(),
        "Failed to save agent groups.",
    );

    const [saveButtonDisabled, setSaveButtonDisabled] = React.useState(true);

    const handleEnvironmentAgentGroupChange = (environment: Environment) => {
        if (!environments) {
            return;
        }
        const idx = environments.findIndex(
            (env) => env.internalName === environment.internalName,
        );
        const next = [
            ...environments.slice(0, idx),
            environment,
            ...environments.slice(idx + 1),
        ];
        setEnvironments(next);
        setSaveButtonDisabled(false);
        save.reset();
    };

    const handleSaveClick = () => {
        if (!environments) {
            return;
        }

        setSaveButtonDisabled(true);
        const selections = environments.map((env) => ({
            environment: env.internalName,
            agentGroup: env.selectedAgentGroup,
        }));
        save.mutate({
            url: SHOPCONFIG_ENVIRONMENTS_API_BASE_PATH,
            body: selections,
        });
    };

    return (
        <ContainerWithLoadingSpinner loading={fetch.isFetching}>
            <S.ShopConfigurationPageEnvironmentsContainer>
                <Text tag="h2">Agent groups to run integrations in</Text>

                {environments && environments.length > 0 && (
                    <>
                        {environments.map((env, i) => (
                            <EnvironmentAgentGroupsDropdown
                                key={i}
                                environment={env}
                                onChange={handleEnvironmentAgentGroupChange}
                            />
                        ))}

                        <S.SettingsSaveButtonContainer>
                            <SaveButton
                                onClick={handleSaveClick}
                                disabled={saveButtonDisabled}
                                loading={save.isLoading}
                                success={save.isSuccess}
                                error={!!save.error}
                            >
                                Save
                            </SaveButton>
                        </S.SettingsSaveButtonContainer>
                    </>
                )}

                {environments && environments.length === 0 && (
                    <Text tag="p">No environments</Text>
                )}
            </S.ShopConfigurationPageEnvironmentsContainer>
        </ContainerWithLoadingSpinner>
    );
};

interface EnvironmentProps {
    environment: Environment;
    onChange: (env: Environment) => void;
}

const EnvironmentAgentGroupsDropdown: React.FC<EnvironmentProps> = ({
    environment,
    onChange,
}) => {
    const agentGroupOptions = environment.agentGroups.map((ag) => ({
        label: ag.displayName,
        value: ag.internalName,
    }));

    const handleAgentGroupChange = (agentGroupName: string) => {
        onChange({ ...environment, selectedAgentGroup: agentGroupName });
    };

    return (
        <Dropdown
            label={environment.displayName}
            options={agentGroupOptions}
            selected={environment.selectedAgentGroup}
            onChange={handleAgentGroupChange}
        />
    );
};

export default IntegrationEnvironments;
